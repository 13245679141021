.payroll {
  .staff-tabel-card {
    border-radius: 16px;

    .card-body {
      padding: 16px;
    }

    .clear-btn {
        padding: 0.75rem 1.5rem;
        color: $Primary-Medium;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
        background: none;
        @media (max-width: 991px) {
          margin-bottom: 8px;
        }
      }

    // .app-search {
    //   height: auto;
    // }
    .nav-border-top {
      border-bottom: none;
      @media (max-width: 430px) {
        display: flex;
        justify-content: space-evenly;
      }
    }

    .payroll-table {
      .table-centered {
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 600;
              color: $Text-Dark;
              padding: 16px;
            }
          }
        }

        tbody {
          tr {
            padding: 8px 0px;

            td {
              font-size: 14px;
              font-weight: 400;
              color: $Text-Medium2;
              padding: 16px;

              p {
                font-size: 12px;
                font-weight: 400;
                color: $Text-Light1;
              }

              h5 {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium;
              }

              .pay-approved {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Approved{
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .pay-processed {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Rejected{
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .archived {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .draft {
                font-size: 14px;
                font-weight: 600;
                color: $Text-Light1;
              }
            }
          }
        }
      }
    }
  }
}
