.schedule {
  .schedule-card {
    border-radius: 16px;
    .card-body {
      padding: 16px;
    }
    .table thead tr th {
      &:first-child{
        width: 16% !important;
      }
      width: 12%;
      text-align: center;
      background-color: $Primary-Light2;
      padding: 0.5rem;
    }
    .app-search {
      height: auto;
    }
    .btn-section {
      display: flex;
      justify-content: end;
      gap: 8px;

      .next-btn {
        color: $Background-White;
        font-size: 14px;
        font-weight: 400;
        padding: 4px 12px;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .filter-btn {
        color: $Text-Dark;
        padding: 8px;
        font-size: 13px;
        font-weight: 400;
        border: none;
        display: flex;
        align-items: center;

        .ri-list-settings-line {
          color: $Text-Medium2;
          font-size: 16px;
        }
      }
    }
  }
  .dropdown {
    h5{
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
  .app-search {
    input {
      border-radius: 8px;
      height: auto;
      width: 260px;
      background-color: $Background-BG;
    }
  }
  .filter-btn {
    background: $Background-BG !important;
  }
  .week-table {
    div{
      padding: 1px;
      border-radius: 8px;
      height: 100%;
      text-align: center;
    }
    .notes{
      position: absolute;
      bottom: 2px;
      right: 2px;
    }
    .clock_in{
      position: absolute;
      bottom: 2px;
      left: 2px;
    }
    .night_shift{      
      position: absolute;
      top: 2px;
      right: 2px;
    }
    .conflict_shift{      
      position: absolute;
      top: 2px;
      right: 25px;
    }
    .data-name {
      padding: 0.2rem;
      margin-block: 4px;
      align-items: center;
      .name-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: $Primary-Light2;
        color: $Primary-Medium;
      }
      .name-title {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      .role {
        color: $Text-Light1;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
    .unpublished {
      background-color: $States-Warning-light;
      color: $States-Warning;
      min-height: 56px !important;
    }
    .archived{
      background-color: $States-Negative_light;
      color: $States-Negative_Error;
      min-height: 56px !important;
      cursor: not-allowed;
    }
    .terminated {
      background-color: $States-Negative_light;
      color: $States-Negative_Error;
    }
    .finished {
      background-color: $States-Success_Light;
      color: $Background-Success;
      min-height: 56px !important;
      // cursor: not-allowed;
    }
    .published {
      background-color: #dfebfc;
      color: #2272ea;
      min-height: 56px !important;
    }
    .leave {
      min-height: 56px !important;
      border: 1px solid $Text-Light2;
      background-color: $Background-White;
      color: $Text-Medium3;
    }
    .Unavailable {
      min-height: 84px;
      border: 1px solid $Text-Light2;
      background-color: $Background-White;
      color: $Text-Medium3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .available {      
      background-color: $Text-Light3;
      color: $Text-Light1;
      min-height: 56px !important;
    }
    .vacant {
      background-color: #f5ebe0;
      color: #95847a;
    }
  }
}
.events_div{
    vertical-align: bottom !important;
    padding: 0.2rem !important;
  .child_event {
    margin-bottom: 0.2rem;  
    position: relative;
    cursor: pointer;
  }
}
.addShiftBtn{
  padding: 0 !important;
}
.shift-note-tooltip {
  text-align: left;
  color: $Text-Medium2;
  font-size: 12px;
  font-weight: 500;
  p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
}
.total-shift-tooltip {
  display: flex;
  align-items: center;
  color: $Background-White;
  font-size: 14px;
  font-weight: 600;
}

.round-shift-tooltip {
  font-size: 14px;
  color: $Background-White;
  font-weight: 500;
}

.shift-canvas {
  background: $Background-BG-light-3;
  h5 {
    color: $Text-Medium;
    font-size: 24px;
    font-weight: 500;
  }
  .offcanvas-body {
    overflow-x: hidden;
    .card {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }
    label {
      color: $Text-Medium3 !important;
    }
    select {
      color: $Text-Light2;
    }
    .app-search {
      input {
        border-radius: 8px;
        height: 48px;
        background-color: $Background-BG;
      }
    }
    .button-row {
      align-items: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: $Primary-Light2;
        color: $Primary-Medium;
      }
      p {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .employee-row {
      align-items: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: $Primary-Light2;
        color: $Primary-Medium;
      }
      .item-name {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      .item-role {
        color: $Text-Light1;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
      }
      .dropdown-toggle::after {
        display: none;
      }
    }
    .line {
      margin-block: 16px;
      border-bottom: 1px solid $Text-Light3;
    }
  }
  .card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    h5 {
      color: $Text-Dark;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
.shift-option-canvas {
  width: 700px !important;
  border-radius: 24px 0px 0px 24px;
  .shift-column {
    text-align: center;
    border-radius: 24px 0px 0px 24px;
    padding: 40px 24px;
    background-color: $Background-BG;
    height: 100vh;
    span {
      margin: auto;
      color: $Background-White;
      font-size: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background: $Primary-Medium;
      margin-bottom: 16px;
    }
    .title {
      color: $Text-Medium;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .role {
      color: $Text-Light1;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 8px;
    }
    .active-time {
      color: $Text-Medium;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 40px;
    }
    .date {
      color: $Text-Medium;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 32px;
    }
    .custom-verti-nav-pills .nav-link::before {
      display: none;
    }
    .nav-link {
      padding: 16px 0px;
      margin-bottom: 24px;
      background-color: $Background-White;
      color: $Text-Light1;
      font-size: 14px;
      font-weight: 500;
      // width: 149px;
      // height: 116.583px;
    }
    .nav-link.active {
      background-color: $Primary-Light2;
      color: $Primary-Medium;
    }
  }
  .shift-form {
    .offcanvas-title {
      color: $Text-Dark;
      font-size: 24px;
      font-weight: 500;
    }
    .form-head {
      margin-block: 1rem;
      color: $Text-Medium;
      font-size: 18px;
      font-weight: 500;
    }
    .ri-user-line,
    .ri-calendar-event-line,
    .ri-time-line,
    .ri-briefcase-line,
    .ri-bank-line,
    .ri-calendar-line,
    .ri-book-open-line,
    .bx-face,
    .ri-mail-line,
    .ri-account-circle-line {
      color: $Text-Light2;
    }
    .note-box {
      background-color: $Background-BG;
      padding: 16px 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      .title {
        color: $Text-Medium2;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .content {
        color: $Text-Medium2;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
  .shift-footer {
    width: -webkit-fill-available;
    position: fixed;
    bottom: 0;
    padding: 0 10px 24px 20px;
    .time-card {
      padding: 16px 8px;
      margin-inline: 8px;
      background-color: $Background-BG;
      border-radius: 8px;
      .time-bar {
        flex: 1;
        p {
          color: $Text-Light2;
          font-size: 10px;
          font-weight: 600;
        }
        .standing-line {
          width: 1px;
          height: 27px;
          background-color: $Text-Light2;
          margin: auto;
        }
      }
      .total {
        p {
          color: $Text-Dark;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .line {
      border-bottom: 1px solid var(--tb-border-color);
      margin-block: 24px 12px;
      margin-left: -20px ;
    }
    .shift-btn {
      color: $Background-White;
      font-size: 14px;
      font-weight: 600;
      padding-inline: 16px;
      border-radius: 8px;
      background: $Primary-Gradient;
    }
  }
}
.profile-view-canvas {
  width: 600px !important;
  .offcanvas-title {
    color: $Text-Dark;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .profile-details {
    text-align: center;
    border-bottom: 1px solid $Text-Light3;
    .name-logo {
      margin: auto;
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.5rem;
      background-color: $Primary-Medium;
      color: $Background-White;
      font-size: 1.5rem;
      font-weight: 400;
      border-radius: 50%;
      margin-bottom: 1rem;
    }
    .name {
      color: $Text-Medium;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .profession {
      color: $Text-Medium2;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .role {
      color: $Text-Light1;
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
  }
  .shift-status {
    .name-logo {
      margin: auto;
      width: 4.375rem;
      height: 4.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $Primary-Medium;
      color: $Background-White;
      font-size: 1.5rem;
      font-weight: 400;
      border-radius: 50%;
      margin-bottom: 1rem;
    }
    .name {
      color: $Text-Medium;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .role {
      color: $Text-Light1;
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
      span {
        color: $Text-Medium;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    .status {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Success_Light;
      font-size: 12px;
      font-weight: 600;
      color: $Background-Success;
      text-align: center;
    }
  }
  .other-details {
    .detail-col {
      border-bottom: 1px solid $Text-Light3;
      .title {
        color: $Text-Medium3;
        font-size: 0.875rem;
        font-weight: 400;
        margin-block: 1.5rem 0.5rem;
      }
      .details {
        color: $Text-Medium;
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }
    }
  }
}


.fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: relative;
}

.fc-daygrid-day-number {
  align-self: flex-start;
}

.fc-daygrid-day-events {
  width: 100%;
}

.fc-daygrid-day-bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fc-daygrid-day-plus-icon button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  margin-top: 1rem;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number{
  background-color: transparent !important;
}
.fc-toolbar h2 {
  display: inline;
}
.fc .fc-daygrid-day.fc-day-today{
  background-color: transparent;
}
.location-bar{
  .chat-list > li{
    border: 1px solid #ccc;
    border-bottom: 0;
    &:last-child{
      border-bottom: 1px solid #ccc;
    }
    a{
      padding-left: 8px;
    }
  }
}