//
// _authentication.scss
//

.auth-card {
  background: $Primary-Light2 !important;

  .log-in-title {
    font-size: 16px;
    font-weight: 400;
    color: $Text-Medium2;
  }

  .log-in-text {
    font-size: 30px;
    font-weight: 600;
    color: $Text-Dark;
  }

  .credit-project {
    font-size: 14px;
    font-weight: 400;
    color: $Text-Medium3;
  }

  .signback-side {
    max-width: 90%;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .background-side {
    max-width: 59%;

    // width: 100%;
  }

  .auth-effect-main {
    @media (min-width: 576px) {
      width: 444px;
      height: 444px;
      border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

      .effect-circle-1 {
        width: 396px;
        height: 396px;
        border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

        .effect-circle-2 {
          width: 348px;
          height: 348px;
          border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

          .effect-circle-3 {
            width: 300px;
            height: 300px;
            border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);
          }
        }
      }
    }

    .auth-user-list {
      li {
        position: absolute;

        &:nth-child(1) {
          bottom: 82px;
          left: 48px;
        }

        &:nth-child(2) {
          top: 95px;
          left: 66px;
        }

        &:nth-child(3) {
          top: 20px;
          right: 98px;
        }

        &:nth-child(4) {
          bottom: -25px;
          right: 144px;
        }

        &:nth-child(5) {
          bottom: 185px;
          right: 35px;
        }
      }
    }
  }
}
.employee-form {
  background: url(../../images/auth/employeeformback.png) center center/cover;
  // height: 100vh;
  .head-title {
    position: relative;
    @media (max-width: 1400px) {
      display: none;
    }
  }
  .employee-form-card {
    .logo {
      margin-top: 1rem;
      margin-left: 2.5rem;
      width: 100%;
      max-width: 8%;
    }
    .form-title {
      padding-block: 216px 440px;
      margin-left: 153px;
      h3 {
        color: $Text-Dark;
        font-size: 3.125rem;
        font-weight: 600;
        margin-bottom: 5px;
      }
      h4 {
        color: $Text-Dark;
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 2.1875rem;
        margin-bottom: 71px;
      }
      p {
        color: $Text-Dark;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 6px;
      }
      .nextline {
        color: #000;
        .red-text {
          color: $Primary-Medium;
          font-size: 16px;
          font-weight: 600;
          line-height: 18.4px;
        }
      }
    }
    .person {
      position: absolute;
      right: 20%;
      bottom: 0%;
    }
  }
}
.employee-signup-form {
  margin-top: 217px;
  @media (max-width: 1400px) {
   padding: 24px;
   margin-top: 0;
  }

  .sai-logo{
    display: none;
    @media (max-width: 1400px) {
       display: flex;
       justify-content: center;
       margin-top: 20%;
     }
  }

  h4 {
    color: $Text-Dark;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 16px;
  }
  p {
    color: $Text-Light1;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 32px;
    margin-inline: 35px;
  }
  label {
    color: $Text-Medium3;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.9px;
  }
  input {
    border-radius: 8px;
    color: $Text-Medium3;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    background-color: $Primary-Light2;
  }
  .ri-mail-line,
  .ri-lock-password-line {
    color: $Text-Light2;
    font-size: 16px;
  }
  .submit-btn {
    width: 100%;
    color: $Background-White;
    font-size: 14px;
    font-weight: 600;
    line-height: 15.9px;
    padding: 16px 12px;
    border-radius: 8px;
    background: $Primary-Gradient;
    margin-top: 8px;
  }
}

.signin-other-title {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    border-top: 1px dashed var(--#{$prefix}border-color);
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 2;
    background-color: var(--#{$prefix}secondary-bg);
    padding: 2px 16px;
  }
}

// auth-pass-inputgroup
.auth-pass-inputgroup {
  input[type="text"] + .btn .ri-eye-fill {
    &:before {
      content: "\ecb6";
    }
  }
}

//passowrd validations
#password-contain {
  display: none;

  p {
    padding-left: 13px;

    &.valid {
      color: $success;

      &::before {
        position: relative;
        left: -8px;
        content: "✔";
      }
    }

    &.invalid {
      color: $danger;

      &::before {
        position: relative;
        left: -8px;
        content: "✖";
      }
    }
  }
}

.password-addon {
  z-index: 5;
}

.signup-organization {
  .wait-user {
    margin-top: 170px;
    margin-bottom: 32px;
    h3 {
      font-size: 30px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
      text-align: center;
      margin-top: 16px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $Text-Medium3;
      margin-bottom: 16px;
      text-align: center;
    }
    h4 {
      font-size: 24px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
    }
  }

  .logo-sign {
    margin-top: 200px;
    margin-bottom: 32px;

    h5 {
      font-size: 30px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 16px;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      color: $Text-Medium3;
      margin-bottom: 0;
    }

    h4 {
      font-size: 30px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 16px;
    }

    .text-muted {
      font-size: 13px;
      font-weight: 500;
      color: $Text-Medium3;
    }

    h3 {
      font-size: 30px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 16px;
      text-align: center;
      margin-top: 12px;
    }

    .email-sent {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Light1;
      margin-bottom: 16px;
      text-align: center;

      b {
        font-size: 18px;
        font-weight: 600;
        color: $Text-Medium3;
      }
    }

    .send-link {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Light1;
    }
  }

  .all-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid $Text-Light3;
    border-bottom: 1px solid $Text-Light3;
    cursor: pointer;

    .add-organization {
      display: flex;
      align-items: center;
      gap: 20px;

      h5 {
        font-size: 16px;
        font-weight: 500;
        color: $Text-Medium;
        margin-bottom: 4px;
      }

      p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 500;
        color: $Text-Medium3;
      }

      span {
        width: 50px;
        height: 50px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        .bi-plus {
          font-size: xx-large;
        }

        .bi-arrow-right {
          font-size: 20px;
        }
      }
    }

    .bi-chevron-right {
      font-size: 24px;
      color: $Primary-Medium;
    }
  }
}

.create-organization {
  .card {
    .card-body {
      padding: 16px 40px;
    }

    .sai-logo {
      max-width: 15%;
    }

    .already-account {
      display: flex;
      justify-content: end;
      align-items: center;

      p {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium;
      }
    }
  }

  .organization-details {
    padding-left: 40px;
    padding-right: 40px;

    .progress-bar {
      margin-left: 170px;
      margin-right: 208px;
    }

    .progress-bar-tab {
      .nav-item {
        display: flex;
        align-items: baseline;
        gap: 8px;

        .nav-name.active {
          font-weight: 600;
          text-transform: uppercase;
          color: $Primary-Medium;
        }
        .nav-name {
          font-size: 14px;
          font-weight: 600;
          color: $Text-Medium3;
          text-transform: uppercase;

          @media (max-width:1400px){
            display: none !important
          }

        }
      }
    }
  }

  .company-details-1 {
    border-radius: 16px;
    height: 100%;

    .card-body {
      padding: 32px;
    }
    .card-footer {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      margin-bottom: 0;
    }

    .skip-account {
      font-size: 16px;
      font-weight: 600;
      color: $Primary-Dark;
      margin-bottom: 0;
      text-align: end;
      cursor: pointer;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Light1;
      margin-bottom: 48px;
    }

    .next-page1 {
      padding-top: 24px;
      border-top: 1px solid var(--text-Light-3, $Text-Light3);
      // margin-top: 170px;
      text-align: end;

      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    }

    .previous-page {
      padding-top: 24px;
      border-top: 1px solid var(--text-Light-3, $Text-Light3);
      // margin-top: 170px;
      display: flex;
      justify-content: space-between;

      .previous-btn {
        padding: 0.75rem 1.5rem;
        color: #d40d12;
        font-size: 0.875rem;
        font-weight: 600;
        background: none;
        border: none;
      }

      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    }

    .item label {
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 24px;
      background-color: $Background-White;
      padding: 24px;
      text-align: center;
      margin-bottom: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      position: relative;
      border: 1px solid #d9e1e6;
      font-size: 15px;
      font-size: 0.9375rem;
      color: #777;
      cursor: pointer;
    }

    @media (max-width: 991px) {
      .item label {
        padding: 30px;
      }
    }

    .item label h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium2;
      text-align: left;
      margin-bottom: 8px;
    }

    .item label p {
      font-size: 13px;
      font-weight: 400;
      color: $Text-Light1;
      margin-bottom: 0;
      text-align: start;
    }

    .item label span {
      width: 78px;
      height: 78px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background: $Primary-Light2 !important;

      .logo {
        font-size: 44px;
        color: $Primary-Dark;
      }
    }

    .item label:hover {
      -webkit-box-shadow: 0 2px 40px #e0e5f0;
      -moz-box-shadow: 0 2px 40px #e0e5f0;
      box-shadow: 0 2px 40px #e0e5f0;
      -webkit-transform: translateY(-3px);
      -moz-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      -o-transform: translateY(-3px);
      transform: translateY(-3px);
    }

    .item input[type="checkbox"]:not(old),
    .item input[type="radio"]:not(old) {
      visibility: hidden;
    }

    .item input[type="checkbox"]:not(old):checked + label,
    .item input[type="radio"]:not(old):checked + label,
    .item input[type="checkbox"]:not(old):checked + span + label,
    .item input[type="radio"]:not(old):checked + span + label {
      display: flex;
      border: 1px solid $Primary-Light1;
      -webkit-box-shadow: 0 2px 40px #e0e5f0;
      -moz-box-shadow: 0 2px 40px #e0e5f0;
      box-shadow: 0 2px 40px #e0e5f0;
    }

    .item input[type="checkbox"]:not(old):checked + label:before,
    .item input[type="radio"]:not(old):checked + label:before,
    .item input[type="checkbox"]:not(old):checked + span + label:before,
    .item input[type="radio"]:not(old):checked + span + label:before {
      content: "✔";
      position: absolute;
      right: 20px;
      top: 10px;
      width: 24px;
      height: 24px;
      background: $Primary-Medium;
      color: $Background-White;
      border-radius: 100%;
      font-size: larger;
    }

    .item input[type="checkbox"]:not(old):checked + label figure img,
    .item input[type="radio"]:not(old):checked + label figure img,
    .item input[type="checkbox"]:not(old):checked + span + label figure img,
    .item input[type="radio"]:not(old):checked + span + label figure img {
      -webkit-transform: translate(-50%, -50%) scale(1.2);
      -moz-transform: translate(-50%, -50%) scale(1.2);
      -ms-transform: translate(-50%, -50%) scale(1.2);
      -o-transform: translate(-50%, -50%) scale(1.2);
      transform: translate(-50%, -50%) scale(1.2);
    }

    .checkbox_questions {
      position: relative;
    }

    .checkbox_questions span.error {
      left: -5px;
      top: -35px;
    }

    .checkbox_questions .icheckbox_square-grey {
      margin-right: 10px;
    }
  }

  .external-side {
    padding: 70px;

    h4 {
      font-size: 28px;
      font-weight: 400;
      text-transform: capitalize;
      color: #000;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #484848;
      margin-bottom: 48px;
    }

    .side-image {
      max-width: 100%;
    }
  }
}
.emp-onboarding {
  .card {
    .card-body {
      padding: 16px 40px;
    }

    .logo-div {
      @media (max-width: 991px) {
        text-align: center;
      }
    }

    .sai-logo {
      max-width: 15%;
    }

    .already-account {
      display: flex;
      justify-content: end;
      align-items: center;

      @media (max-width: 991px) {
        justify-content: center;
        margin-top: 24px;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium;
      }
    }
  }
  .organization-details {
    padding-left: 40px;
    padding-right: 40px;

    .progress-bar {
      margin-left: 188px;
      margin-right: 208px;
      @media (max-width:1400px){
        display: none !important
      }
    }
    .progress-nav .nav {
      margin-inline: 0;
    }
    .progress-nav .progress {
      top: 44%;

      @media (max-width: 991px) {
        display: none;
      }
    }
    .progress-bar-tab {
      .nav-item {
        display: flex;
        align-items: baseline;
        gap: 0;

        .nav-name.active {
          font-weight: 600;
          text-transform: uppercase;
          color: $Primary-Medium;
        }
        .nav-name {
          font-size: 14px;
          font-weight: 600;
          color: $Text-Medium3;
          text-transform: uppercase;
          background-color: #f7f7f7 !important;
          padding-inline: 0.5rem;

          @media (max-width:1400px){
            display: none !important
          }
        }
      }
    }
  }
  .employee-onboarding-form-card {
    .doc-row {
      @media (max-width: 991px) {
        gap: 25px !important;
      }
    }
    .doc-emp {
      @media (max-width: 991px) {
        padding-left: 10px !important;
        margin-bottom: 24px;
      }
    }

    .heading-employee-title {
      @media (max-width: 991px) {
        width: 70%;
      }
    }
    .cancel-btn-div {
      @media (max-width: 991px) {
        width: 30%;
      }
    }
    .flatpickr-input {
      border: 1px solid #dde1ef;
    }
    .form-body {
      height: 525px;
      overflow-y: auto;
    }
    border-radius: 16px;
    padding: 24px 32px;
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      margin-bottom: 0;
      @media (max-width: 430px) {
        font-size: 20px;
      }
    }
    .cancel-btn {
      font-size: 16px;
      padding: 16px;
      font-weight: 600;
      color: $Primary-Dark;
      margin-bottom: 0;
      cursor: pointer;
    }
    .line {
      margin-top: 0.5rem;
      margin-bottom: 24px;
      border-bottom: 1px solid $Text-Light3;
    }
    .sup-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
      color: #999;
    }
    .prev-img {
      height: 6.25rem;
      width: 6.25rem;
      border: 1px dashed $Text-Medium3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bx-face,
    .bx-notepad,
    .bi-briefcase,
    .ri-calendar-event-line,
    .ri-map-pin-line,
    .ri-bank-line,
    .ri-keyboard-box-line,
    .bx-phone-call,
    .ri-mail-line {
      font-size: 16px;
      color: $Text-Light2;
    }
    .declaration {
      label {
        color: $Text-Medium2;
        font-size: 14px;
        font-weight: 500;
      }
      .form-check-input {
        border-color: $Text-Light1;
      }
    }
    .next-page1 {
      padding-top: 24px;
      border-top: 1px solid var(--text-Light-3, $Text-Light3);
      // margin-top: 170px;
      text-align: end;

      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    }

    .previous-page {
      padding-top: 24px;
      border-top: 1px solid var(--text-Light-3, $Text-Light3);
      // margin-top: 170px;
      display: flex;
      justify-content: space-between;

      .previous-btn {
        padding: 0.75rem 1.5rem;
        color: #d40d12;
        font-size: 0.875rem;
        font-weight: 600;
        background: none;
        border: none;
      }

      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    }

    .invalid-border {
      border: none !important;
    }

    .uploadsection {
      display: flex;
      padding: 5px 0px 8px 20px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border: 1px solid $Text-Light3;
      border-radius: 8px;

      p {
        margin-bottom: 0;
        padding: 5px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(71, 71, 71, 0.8);
      }
      .uploadlabel {
        width: 103px;
        height: 35px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $Background-White;
        border-radius: 4px;
        border: 1px solid $Primary-Medium;
        font-size: 11px;
        gap: 8px;
        margin-bottom: 0;
        background-color: $Primary-Medium;
        .bx-plus {
          font-size: initial;
        }
      }
    }
    .closeuser {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5.5px;
      border: none;
      padding-right: 20px !important;
    }
    .add-btn {
      color: $Primary-Medium;
      font-size: 14px;
      font-weight: 600;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .external-side {
    padding: 70px;
    text-align: center;
    h3 {
      margin: auto;
      padding-block: 2rem;
      vertical-align: middle;
      width: 100px;
      height: 100px;
      background-color: $Primary-Medium;
      border-radius: 50%;
      span {
        color: $Background-White;
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
      }
    }
    p {
      margin-top: 20px;
      color: $Text-Medium;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
    }
    .side-image {
      max-width: 100%;
    }
  }
  .registered {
    text-align: center;
    img {
      width: 100%;
      max-width: 10%;
      margin-top: 150px;
    }
    .content {
      margin-block: 2rem;
      h3 {
        color: $Text-Dark;
        font-size: 30px;
        font-weight: 600;
      }
      p {
        color: $Text-Light1;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .dashboard-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
    }
  }
}
