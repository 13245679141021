.timesheet-module {
  .timesheet-table-card {
    border-radius: 16px;

    .card-body {
      padding: 16px;
    }

    // .app-search {
    //   height: auto;
    // }

    .leave-table {
      .table-centered {
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 600;
              color: $Text-Dark;
              padding: 16px;
            }
          }
        }

        tbody {
          tr {
            padding: 8px 0px;

            td {
              font-size: 14px;
              font-weight: 400;
              color: $Text-Medium2;
              padding: 16px;

              p {
                font-size: 12px;
                font-weight: 400;
                color: $Text-Light1;
              }

              h5 {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium;
              }

              .Approve {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Pending {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Reject {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .Archived {
                font-size: 12px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Invited {
                font-size: 12px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Active {
                font-size: 12px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Inactive {
                font-size: 12px;
                font-weight: 600;
                color: $Text-Light1;
              }

              .active-status {
                font-size: 10px;
                font-weight: 400;
                color: $Text-Light1;
              }

              .Approved {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Partially {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Rejected {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
              .Delete {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
            }
          }
        }
      }
    }

    .btn-employee-section {
      .nav-link.active {
        color: $Background-White;
        font-size: 14px;
        font-weight: 600;
        border: none;
        background: $Primary-Medium;
        border-radius: 8px;
        opacity: 0.92;
        display: flex;
        align-items: center;
        gap: 8px;

        .ri-group-line {
          font-size: 20px;
        }
        .ri-archive-line {
          font-size: 20px;
        }
      }

      .sub-btn {
        margin-right: 12px;
      }

      .nav-link {
        color: $Text-Medium3;
        font-size: 14px;
        font-weight: 600;
        border: none;
        background: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        .ri-group-line {
          font-size: 20px;
        }
        .ri-archive-line {
          font-size: 20px;
        }
      }
    }
  }
}

.timesheet-canvas {
  width: 550px !important;
  padding: 16px !important;

  .off-time {
    text-align: end;
    .sub-btn {
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      width: 100px;
      height: 48px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: $Primary-Gradient;
    }
  }

  .offcanvas-footer {
    .approve-reject-btn-timesheet{
    .clear-section-timesheet {
      .clear-btn {
        padding: 0.75rem 1.5rem;
        color: $Primary-Medium;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
        background: none;
        width: 100%;
        @media (max-width:991px) { 
          margin-bottom: 8px;
         }
      }
    }

    .Apply-section-timesheet {
      text-align: end;

      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        width: 100%;
        background: $Primary-Gradient;
      }
    }
  }
}

  .add-header {
    padding: 0;
    padding-bottom: 20px !important;

    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Medium;
    }

    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      color: $Background-White;
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 80px;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Text-Light2;
    }

    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }

  .offcanvas-body {
    padding: 16px 0px 0px 0px;
    overflow-x: hidden;

    .nav-border-top-primary .nav-link.active {
      padding: 12px 16px;
      border-radius: 4px;
      border-top: 2px solid var(--Primary-Dark);
      opacity: 0.92;
      font-size: 14px;
      font-weight: 600;
      color: $Primary-Dark;
      display: flex;
      align-items: center;
      gap: 8px;
      .ri-group-line {
        font-size: 18px;
      }
      .ri-archive-line {
        font-size: 18px;
      }
    }
    .nav-border-top .nav-link {
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #707070;
      .ri-group-line {
        font-size: 18px;
      }
      .ri-archive-line {
        font-size: 18px;
      }
    }
    .Rejected {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Negative_light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Negative_Error;
      text-align: center;
    }

    .Pending {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Warning-light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Warning;
      text-align: center;
    }

    .Approved {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Success_Light;
      font-size: 12px;
      font-weight: 600;
      color: $Background-Success;
      text-align: center;
    }

    .Delete {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Negative_light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Negative_Error;
      text-align: center;
    }

    .status-col {
      padding: 24px 12px;
      border-bottom: 1px solid $Text-Light3;

      .status-title {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 8px;
      }

      .status-value {
        font-size: 14px;
        font-weight: 500;
        color: $Text-Medium;
        margin-bottom: 0;
      }
    }

    .status-table {
      border-bottom: 1px solid $Text-Light3;
      padding-bottom: 24px;
    }

    .table {
      thead {
        tr {
          border-radius: 8px 8px 0px 0px;
          background: var(--Background-BG, $Background-BG);
          border: $Background-White;

          th {
            font-size: 12px;
            font-weight: 600;
            color: $Text-Dark;
            padding: 8px 16px;
            vertical-align: middle;
          }

          .User {
            width: 119px;
          }

          .Previous {
            width: 90px;
          }
        }
      }

      tbody {
        tr {
          border: $Background-White;

          td {
            font-size: 12px;
            font-weight: 400;
            color: $Text-Medium2;
            padding: 12px 16px;
          }
        }
      }
    }

    .status-comment {
      padding-bottom: 24px;
      padding-top: 24px;
      border-bottom: 1px solid $Text-Light3;

      p {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium;
        margin-bottom: 6px;

        span {
          font-size: 14px;
          font-weight: 500;
          color: $Text-Medium2;
        }
      }

      .status-cmt {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 0;
      }

      h5 {
        font-size: 12px;
        font-weight: 400;
        color: $Text-Medium2;
      }
    }
  }
}

.add-timesheet {
  border-radius: 24px 24px 0px 0px;
  padding: 24px;

  .offcanvas-header {
    padding: 8px 0px 20px 0px;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Medium;
    }
  }

  .flatpickr-input {
    border-radius: 8px;
    border: 1px solid var(--text-Light-3, $Text-Light3);
    background: var(--Background-White, $Background-White);
  }

  .offcanvas-body {
    overflow-x: hidden;
    padding: 24px 0px;
  }

  .Round-btn {
    font-size: 14px;
    font-weight: 600;
    color: $Primary-Dark;
    border-radius: 8px;
    background: var(--$Primary-Light2, $Primary-Light2);
    border: none;
    display: flex;
    width: 120px;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .add-break-section {
    padding: 16px 10px;
    border-top: 1px solid $Background-BG;
  }
  .add-break-btn {
    background: none !important;
    border: none;
    padding: 0;
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    .ri-add-line {
      font-size: 24px;
    }
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
    color: $Text-Medium;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: $Text-Medium2;
    padding: 12px 16px;
    border-radius: 8px;
    opacity: 0.92;
    background: var(--Background-BG, $Background-BG);
    text-align: center;
    margin-bottom: 0;
  }

  .add-break-checkbox {
    padding: 8px;
    border-radius: 8px;
    background: var(--Background-BG, $Background-BG);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    .form-check-input {
      margin: 0;
      width: 18px;
      height: 18px;
    }
  }
  .break-allow {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 8px;

    .delete-break {
      border-radius: 8px;
      background: var(--Background-BG, $Background-BG);
      color: $Primary-Medium;
      border: none;
      padding-top: 4px;
      padding-bottom: 4px;
      .ri-delete-bin-line {
        font-size: 24px;
      }
    }
  }
}

.singletimesheet-module {
  .singletimesheet-table-card {
    border-radius: 16px;

    .card-body {
      padding: 16px;
    }

    // .app-search {
    //   height: auto;
    // }

    .leave-table {
      .table-centered {
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 600;
              color: $Text-Dark;
              padding: 16px;
            }
          }
        }

        tbody {
          tr {
            padding: 8px 0px;

            td {
              font-size: 14px;
              font-weight: 400;
              color: $Text-Medium2;
              padding: 16px;

              p {
                font-size: 12px;
                font-weight: 400;
                color: $Text-Light1;
              }

              h5 {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium;
              }

              .Approve {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Pending {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Reject {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .Archived {
                font-size: 12px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Invited {
                font-size: 12px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Active {
                font-size: 12px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Inactive {
                font-size: 12px;
                font-weight: 600;
                color: $Text-Light1;
              }

              .active-status {
                font-size: 10px;
                font-weight: 400;
                color: $Text-Light1;
              }

              .Approved {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Partially {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Rejected {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
              .Delete {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
            }
          }
        }
      }
    }

    .btn-employee-section {
      .nav-link.active {
        color: $Background-White;
        font-size: 14px;
        font-weight: 600;
        border: none;
        background: $Primary-Medium;
        border-radius: 8px;
        opacity: 0.92;
        display: flex;
        align-items: center;
        gap: 8px;

        .ri-group-line {
          font-size: 20px;
        }
        .ri-archive-line {
          font-size: 20px;
        }
      }

      .sub-btn {
        margin-right: 12px;
      }

      .nav-link {
        color: $Text-Medium3;
        font-size: 14px;
        font-weight: 600;
        border: none;
        background: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        .ri-group-line {
          font-size: 20px;
        }
        .ri-archive-line {
          font-size: 20px;
        }
      }
    }
  }
}

.off-bottom-timesheet {
  padding: 12px 0px;
  border-top: 1px solid $Text-Light3;
  display: flex;
  justify-content: space-between;

  .form-check-label {
    font-size: 14px;
    font-weight: 400;
    color: $Text-Medium3;
  }

  .off-confirm {
    padding: 8px 2px;
    .next-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
      margin-left: 24px;
    }

    .clear-btn {
      padding: 0.75rem 1.5rem;
      color: $Primary-Dark;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      background: none;
      border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
    }
  }
}
