.admin-dashboard {
  .location-table {
    border-radius: 16px;

    .card-header {
      padding: 16px;
      border-bottom: 1px solid $Text-Light3;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }
    .card-body {
      padding: 16px;
    }


    .apexcharts-legend {
      top: 40% !important;
    }

    .calendar-status {
      font-size: 13px;
      color: $Text-Medium3;
      font-weight: 500;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 4px;

      .icon-1 {
        font-size: 12px;
        color: $Text-Light1;
      }
      .icon-2 {
        font-size: 12px;
        color: $Background-Success;
      }
      .icon-3 {
        font-size: 12px;
        color: $Primary-Medium;
      }
      .icon-4 {
        font-size: 12px;
        color: #3981ec;
      }
    }

    .calendar-col {
      // border-bottom: 1px solid $Text-Light3;
      .date-calc {
        font-size: 12px;
        font-weight: 500;
        color: #474747;
        margin-bottom: 4px;
        margin-top: 16px;
      }

      .Finished {
        border-left: 3px solid var(--States-Success_Positive, #50b478);
        background: var(--States-Success_Light, #dcf0e6);
        color: #50b478;
      }
      .Upcoming {
        border-left: 3px solid #3981ec;
        background: #dfebfc;
        color: #3981ec;
      }
      .Leave,
      .Unavailable,
      .Available {
        border-left: 3px solid var(--Text-Light1, #999);
        background: var(--Background-BG-Light-2, #f8f8f8);
        color: #707070;
      }
      .Terminated {
        border-left: 3px solid var(--States-Negative_Error, #f03c50);
        background: var(--States-Negative_light, #ffe6eb);
        color: #f03c50;
      }
      .div-calendar {
        min-height: 76px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 12px;
        border-radius: 8px;

        .time-calc {
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .place-calc {
          font-size: 11px;
          font-weight: 500;
          margin-bottom: 4px;
        }
        .depart-calc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 11px;
            font-weight: 500;
            margin-bottom: 0px;
          }
        }
      }
    }

    .calendar-admin-title {
      display: flex;
      align-items: center;
      .total-location {
        padding: 4px;
        background: $Background-BG;
        .ri-calendar-event-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
      }
    }

    .calendar-navbar {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 8px;

      .calendar-left-icon {
        padding: 8px;
        border-radius: 4px;
        background: $Background-BG !important;
        .ri-arrow-left-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
      }

      .calendar-title-name {
        padding: 8px 8px;
        border-radius: 4px;
        background: $Background-BG;
        font-size: 12px;
        color: $Text-Dark;
        font-weight: 600;
      }

      .calendar-right-icon {
        padding: 8px;
        border-radius: 4px;
        background: $Background-BG !important;
        .ri-arrow-right-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
      }
    }

    h5 {
      font-size: 18px;
      color: $Text-Dark;
      font-weight: 600;
      margin-bottom: 0;

      .total-location {
        padding: 4px 8px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        margin-left: 16px;
        background: $Background-BG !important;
        font-size: 12px;
        color: $Text-Dark;
        font-weight: 600;
        .ri-map-pin-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
        .ri-group-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
        .ri-layout-masonry-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
        .ri-user-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
        .ri-file-text-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
        .ri-calendar-line {
          font-size: 16px;
          color: $Primary-Medium;
        }
      }
    }
    .view-details-dropdown {
      display: flex;
      justify-content: end;
      .form-select-sm {
        border: none;
        font-size: 12px;
        color: $Text-Medium3;
        font-weight: 400;
      }
    }
    .view-details {
      font-size: 12px;
      color: $Text-Medium3;
      font-weight: 400;
      margin-bottom: 0;
    }

    .table {
      thead {
        border-radius: 8px 8px 0px 0px;
        th {
          background: var(--Background-BG, $Background-BG);
          border-color: $Background-White;
          font-size: 14px;
          color: $Text-Dark;
          font-weight: 600;
          padding: 16px;
        }
      }
      tbody {
        tr {
          border-color: $Background-White;
          td {
            font-size: 14px;
            color: $Text-Medium2;
            font-weight: 400;
            padding: 16px;

            .user-details {
              display: flex;
              align-items: center;
              gap: 16px;

              .username {
                font-size: 14px;
                color: $Text-Medium;
                font-weight: 400;
                margin-bottom: 0px;
              }
              .useremial {
                font-size: 12px;
                color: $Text-Light1;
                font-weight: 400;
                margin-bottom: 0;
              }
            }
            .status-name {
              font-size: 12px;
              color: $Text-Light1;
              font-weight: 600;
              margin-bottom: 0;
            }

            .awaited {
              font-size: 14px;
              font-weight: 600;
              background: $States-Warning;
              color: #fff;
            }

            .clockout{
              font-size: 14px;
              font-weight: 600;
              background: #3498db;
              color: #fff;
            }

            .missed{
              font-size: 14px;
              font-weight: 600;
              color: #fff;
              background:$States-Negative_Error ;
            }

            .clockin{
              font-size: 14px;
              font-weight: 600;
              color: #fff;
              background: $Background-Success;
            }

            .missedclockin{
              font-size: 14px;
              font-weight: 600;
              color: #fff;
              background: $States-Negative_Error;
            }

            .status-time {
              font-size: 9px;
              color: $Text-Light1;
              font-weight: 400;
              margin-bottom: 0;
            }
            .Invited {
              font-size: 12px;
              color: $States-Warning;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .overflow-hidden {
    border-radius: 16px;
    // height: 122px;

    .card-body {
      padding: 24px;
    }


    .avatar-lg{
      border: 5px solid #50B478 !important;
    }
    .breakitemtime{
      border: 5px solid  #F0B414 !important;
    }

    .card-footer {
      .archive-employee-btn {
        text-align: center;

        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
          color: $Text-Medium;
          cursor: pointer;

          span {
            font-size: 14px;
            font-weight: 600;
            color: $Primary-Medium;
          }
        }
      }
    }

    .profile-status {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .card-title {
      margin-bottom: 16px !important;
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium;
    }

    .user-name {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }

    .user-role {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 8px;
    }
    .user-role-title {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium2;
      margin-bottom: 16px;
    }
    .user-shift-title {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium3;
      margin-bottom: 24px;
    }
    .shift-started {
      font-size: 14px;
      line-height: normal;
      color: $Background-Success;
      margin-bottom: 0;
      font-weight: 600;
      // margin-bottom: 8px;
    }
    .breakstart {
      font-size: 14px;
      line-height: normal;
      color: $States-Warning;
      margin-bottom: 0;
      font-weight: 600;
    }
    .shift-start-btn {
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 100%;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 600;
      color: $Background-White;
      margin-bottom: 24px;
    }

    .start-shift {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      .takebreak-btn {
        border-radius: 8px;
        background: none;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 600;
        color: $Primary-Dark;
        margin-bottom: 24px;
      }
      .shift-end-btn {
        border-radius: 8px;
        background: $Primary-Gradient;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 600;
        color: $Background-White;
        margin-bottom: 24px;
      }
    }

    .shift-time-title {
      font-size: 13px;
      font-weight: 500;
      color: $Text-Medium3;
      margin-bottom: 4px;
    }

    .shift-time {
      font-size: 13px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 16px;
    }

    .shift-timemeal {
      font-size: 13px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 8px;
    }

    .shift-details {
      display: flex;
    }

    .next-btn {
      padding: 8px;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 4px;
      border: none;
      background: $Primary-Gradient;
    }
  }

  .user-section {
    .nav-secondary .nav-link.active {
      border-radius: 8px;
      opacity: 0.92;
      background: var(--Primary-rad-Medium, $Primary-Medium);
      font-size: 14px;
      font-weight: 600;
      color: $Background-White;
    }

    .nav-secondary .nav-link {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
    }

    .nav-secondary.arrow-navtabs .nav-link.active::before {
      border-top-color: $Primary-Medium;
    }

    .section-1 {
      border-radius: 16px;
      // height: 80vh;

      .card-header {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom: 1px solid $Text-Light3;
        padding-top: 24px;
      }

      .card-body {
        min-height: 614px;
      }

      .card-footer {
        padding: 12px 24px 24px 24px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      .flatpickr-input {
        border-radius: 8px;
        border: 1px solid var(--text-Light-3, $Text-Light3);
        background: var(--Background-White, $Background-White);
      }
      .basic-details-title {
        font-size: 16px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 16px;
      }

      .personal-details-title {
        font-size: 18px;
        font-weight: 600;
        color: $Text-Dark;
        padding-bottom: 12px;
      }

      .cancel-btn {
        padding: 16px;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 8px;
        border-color: $Primary-Dark;
        background: none;
        width: 146px;
      }

      .next-btn {
        padding: 16px;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 8px;
        border: none;
        background: $Primary-Gradient;
        width: 146px;
      }

      .input-title {
        margin-bottom: 19.2px;
        font-size: 16px;
        font-weight: 600;
        color: $Text-Dark;
      }      

      .uploadsection {
        display: flex;
        padding: 5px 0px 8px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border: 1px solid $Text-Light3;
        border-radius: 8px;

        p {
          margin-bottom: 0;
          padding: 5px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(71, 71, 71, 0.8);
        }

        .uploadlabel {
          width: 103px;
          height: 35px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $Background-White;
          border-radius: 4px;
          border: 1px solid $Primary-Medium;
          font-size: 11px;
          gap: 8px;
          margin-bottom: 0;
          background-color: $Primary-Medium;

          .bx-plus {
            font-size: initial;
          }
        }
      }

      .closeuser {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5.5px;
        border: none;
        padding-right: 20px !important;
      }

      .add-btn {
        color: $Primary-Medium;
        font-size: 14px;
        font-weight: 600;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .update-pay-rate-title {
        font-size: 14px;
        font-weight: 500;
        color: $Text-Dark;
      }

      .pay-rate-details {
        border-radius: 8px;
        background: var(--Background-BG, $Background-BG);
        width: -webkit-fill-available;
        padding: 8px 12px;
        margin-bottom: 24px;

        h5 {
          font-size: 14px;
          font-weight: 400;
          color: $Text-Medium3;
          margin-bottom: 0px;
        }

        .pay-rate-price {
          display: flex;
          align-items: center;
          gap: 8px;

          .ri-coins-line {
            font-size: 24px;
            color: $Text-Light2;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            color: $Text-Medium2;
            margin-bottom: 0;
          }
        }
      }
    }

    .section-document {
      border-radius: 16px;
      // height: 79vh;

      .card-body {
        padding-right: 0px;
        min-height: 614px;
      }

      .card-header {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom: 1px solid $Text-Light3;
        padding-top: 24px;
      }

      .card-footer {
        padding: 12px 24px 24px 24px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      .flatpickr-input {
        border-radius: 8px;
        border: 1px solid var(--text-Light-3, $Text-Light3);
        background: var(--Background-White, $Background-White);
      }

      .basic-details-title {
        font-size: 16px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 16px;
      }

      .personal-details-title {
        font-size: 18px;
        font-weight: 600;
        color: $Text-Dark;
        padding-bottom: 12px;
      }

      .cancel-btn {
        padding: 16px;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 8px;
        border-color: $Primary-Dark;
        background: none;
        width: 146px;
      }

      .next-btn {
        padding: 16px;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        width: 146px;
        border-radius: 8px;
        border: none;
        background: $Primary-Gradient;
      }

      .input-title {
        margin-bottom: 19.2px;
        font-size: 16px;
        font-weight: 600;
        color: $Text-Dark;
      }

      .add-doc {
        margin-right: 15px;
      }

      .uploadsection {
        display: flex;
        padding: 5px 0px 8px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border: 1px solid $Text-Light3;
        border-radius: 8px;

        p {
          margin-bottom: 0;
          padding: 5px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(71, 71, 71, 0.8);
        }

        .uploadlabel {
          width: 103px;
          height: 35px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $Background-White;
          border-radius: 4px;
          border: 1px solid $Primary-Medium;
          font-size: 11px;
          gap: 8px;
          margin-bottom: 0;
          background-color: $Primary-Medium;

          .bx-plus {
            font-size: initial;
          }
        }
      }

      .closeuser {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5.5px;
        border: none;
        padding-right: 20px !important;
      }

      .add-btn {
        color: $Primary-Medium;
        font-size: 14px;
        font-weight: 600;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .calendar-wrapper {
      .fc-header-toolbar {
        padding-bottom: 1.5rem !important;
        margin-bottom: 1.5rem !important;
        border-bottom: 2px dashed $Text-Light2 !important;
      }
      .fc-toolbar-chunk {
        .fc-today-button {
          border-radius: 4px !important;
          background: $Background-BG !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          color: $Text-Dark !important;
          border: none;
          padding: 8px !important;
        }

        .fc-prev-button {
          border-radius: 4px !important;
          background: $Background-BG !important;
          color: $Primary-Medium;
          padding: 8px !important;
          border: none;
          margin-right: 8px;
        }

        .fc-next-button {
          border-radius: 4px !important;
          background: $Background-BG !important;
          color: $Primary-Medium;
          padding: 8px !important;
          border: none;
        }

        .btn-group .btn.active {
          background: $Primary-Medium !important;
          border: none;
          margin: 0px !important;
        }
      }
      .fc-daygrid-day {
        height: 95px;
        .fc-daygrid-day-events {
          margin-inline: 0.5rem;
          .fc-event {
            border-radius: 0.25rem !important;
          }
        }
      }
      .fc {
        border-radius: 1rem;
        .fc-dayGridMonth-view {
          padding-top: 0;
        }
      }
      .fc-day-today {
        background-color: #f8f8f8;
        border: 2px solid var(--tb-border-color) !important;
      }
      .calendar-footer {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        .add-event {
          display: flex;
          align-items: center;
          span {
            margin-right: 0.5rem;
          }
        }
      }
      .cancel-shift {
        background-color: $States-Negative_light;
        color: $States-Negative_Error;
      }
      .completed-shift {
        background-color: $States-Success_Light;
        color: $Background-Success;
      }
    }
  }
}

.upcomingshift-canvas {
  .offcanvas-title {
    font-size: 24px;
    color: $Text-Medium;
    font-weight: 600;
  }

  .shift-upcoming-details {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $Text-Light3;

    .user-shift {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      .user-name {
        font-size: 14px;
        color: $Text-Medium;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .shift-title {
      font-size: 12px;
      color: $Text-Medium;
      font-weight: 600;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $Text-Light3;
    }

    .shift-name {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 0;
    }
    .shift-note {
      font-size: 14px;
      color: $Text-Medium2;
      font-weight: 500;
      margin-bottom: 0;
    }

    .shift-times {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .shift-name {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 0;
      }
      .shift-time {
        font-size: 14px;
        color: $Text-Medium;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    .shift-apply-btn {
      width: 100%;
      padding: 8px 16px;
      margin-top: 16px;
      border-radius: 8px;
      font-size: 14px;
      color: $Background-White;
      font-weight: 600;
      background: $Primary-Gradient;
    }
    .overall-btn {
      display: flex;
      gap: 16px;
      .shift-approve-btn {
        width: 90%;
        padding: 8px 16px;
        margin-top: 16px;
        border-radius: 8px;
        font-size: 14px;
        color: $Background-White;
        font-weight: 600;
        background: $Primary-Gradient;
      }
      .shift-reject-btn {
        width: 90%;
        padding: 8px 16px;
        margin-top: 16px;
        border-radius: 8px;
        font-size: 14px;
        color: $Primary-Dark;
        font-weight: 600;
        background: none;
      }
    }
  }
}
.takebreak-canvas {
  .offcanvas-title {
    font-size: 24px;
    color: $Text-Medium;
    font-weight: 600;
  }
  .break-image {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .mealbreak {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $Text-Light3;

    .form-check-input {
      margin-left: 4px;
      width: 18px;
      height: 18px;
      margin-top: 0;
      margin-right: 16px;
    }
    .form-check-label {
      font-size: 14px;
      color: $Text-Medium;
      font-weight: 600;
    }
  }
}

.shiftview-canvas {
  .offcanvas-title {
    font-size: 24px;
    font-weight: 600;
    color: $Text-Medium;
  }

  .offcanvas-body {
    padding: 16px;
    overflow-x: hidden;

    .Rejected {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Negative_light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Negative_Error;
      text-align: center;
    }

    .Pending {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Warning-light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Warning;
      text-align: center;
    }

    .Approved {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Success_Light;
      font-size: 12px;
      font-weight: 600;
      color: $Background-Success;
      text-align: center;
    }

    .Delete {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Negative_light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Negative_Error;
      text-align: center;
    }

    .status-col {
      padding: 24px 12px;
      border-bottom: 1px solid $Text-Light3;

      .status-title {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 8px;
      }

      .status-value {
        font-size: 14px;
        font-weight: 500;
        color: $Text-Medium;
        margin-bottom: 0;
      }
    }

    .status-table {
      border-bottom: 1px solid $Text-Light3;
      padding-bottom: 24px;
    }
  }
}

.shiftallview {
  border-top: 1px solid $Text-Light3;
  .viewallshift {
    background: none;
    font-size: 14px;
    color: $Primary-Dark;
    font-weight: 600;
    border: none;
  }
}
.takebreakbtn {
  width: 100%;
  padding: 12px 16px;
  margin-top: 16px;
  border-radius: 8px;
  font-size: 14px;
  color: $Background-White;
  font-weight: 600;
  background: $Primary-Gradient;
}

.endshift-canvas {
  border-radius: 24px 24px 0px 0px;
  min-height: 530px !important;

  .add-header {
    padding: 32px 24px 20px 24px;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }
  }

  .offcanvas-footer {
    border-top: 1px solid $Text-Light3;

    .submit {
      padding: 12px 16px;
      border-radius: 8px;
      font-size: 14px;
      color: $Background-White;
      font-weight: 600;
      background: $Primary-Gradient;
    }
  }

  .offcanvas-body {
    // overflow-y: hidden;

    @media (max-width: 990px) {
      overflow-y: scroll;
    }
  }

  h5 {
    margin-bottom: 16px;
    color: $Text-Medium;
  }
  p {
    font-size: 16px;
    color: $Text-Medium;
    margin-bottom: 16px;
    font-weight: 600;

    .addbreak {
      font-size: 14px;
      color: $Primary-Medium;
      font-weight: 600;
      background: none;
      border: none;
      display: inline-flex;
      align-items: center;
      padding: 0;
      gap: 8px;
      margin-left: 16px;

      .ri-add-line {
        font-size: 24px;
      }
    }
  }

  .flatpickr-input {
    border-radius: 8px;
    border: 1px solid var(--text-Light-3, $Text-Light3);
    background: var(--Background-White, $Background-White);
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
  }

  .remove-btn {
    font-size: 32px;
    background: none;
    border: none;
    color: $States-Negative_Error;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}
