.subcontractor-staff{
    .subcontractor-tabel-card {
      border-radius: 16px;
  
      .card-body {
        padding: 16px;
      }
  
      // .app-search {
      //   height: auto;
      // }
      .nav-border-top {
        border-bottom: none;
        @media (max-width: 430px) {
          display: flex;
          justify-content: space-evenly;
        }
      }
  
      .subcontractor-table {
        .table-centered {
          thead {
            tr {
              th {
                font-size: 14px;
                font-weight: 600;
                color: $Text-Dark;
                padding: 16px;
              }
            }
          }
  
          tbody {
            tr {
              padding: 8px 0px;
  
              td {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium2;
                padding: 16px;
  
                p {
                  font-size: 12px;
                  font-weight: 400;
                  color: $Text-Light1;
                }
  
                h5 {
                  font-size: 14px;
                  font-weight: 400;
                  color: $Text-Medium;
                }
  
                .Approve {
                  font-size: 14px;
                  font-weight: 600;
                  color: $Background-Success;
                }
  
                .Pending {
                  font-size: 14px;
                  font-weight: 600;
                  color: $States-Warning;
                }
  
                .Reject {
                  font-size: 14px;
                  font-weight: 600;
                  color: $States-Negative_Error;
                }
  
                .Inactive {
                  font-size: 12px;
                  font-weight: 600;
                  color: $States-Warning;
                }
  
                .Invited {
                  font-size: 12px;
                  font-weight: 600;
                  color: $Background-Success;
                }
  
                .Active {
                  font-size: 12px;
                  font-weight: 600;
                  color: $Background-Success;
                }
  
                .Inactive {
                  font-size: 12px;
                  font-weight: 600;
                  color: $Text-Light1;
                }
  
                .active-status {
                  font-size: 10px;
                  font-weight: 400;
                  color: $Text-Light1;
                }
  
                .Approved {
                  font-size: 14px;
                  font-weight: 600;
                  color: $Background-Success;
                }
  
                .Partially {
                  font-size: 14px;
                  font-weight: 600;
                  color: $States-Warning;
                }
  
                .Rejected {
                  font-size: 14px;
                  font-weight: 600;
                  color: $States-Negative_Error;
                }
                .Delete {
                  font-size: 14px;
                  font-weight: 600;
                  color: $States-Negative_Error;
                }
              }
            }
          }
        }
      }
  
      .btn-employee-section {
        .nav-border-top-primary .nav-link.active {
          padding: 12px 16px;
          border-radius: 4px;
          border-top: 2px solid var(--Primary-Dark);
          opacity: 0.92;
          font-size: 14px;
          font-weight: 600;
          color: $Primary-Dark;
          display: flex;
          align-items: center;
          gap: 8px;
          .ri-group-line {
            font-size: 18px;
          }
          .ri-archive-line {
            font-size: 18px;
          }
        }
        .nav-border-top .nav-link {
          padding: 12px 16px;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #707070;
          .ri-group-line {
            font-size: 18px;
          }
          .ri-archive-line {
            font-size: 18px;
          }
        }
      }
    }
  }

  .view-subcontractor{
    border-radius: 24px 24px 0px 0px;
    padding: 24px;
  
    .add-header {
      padding: 0;
      padding-bottom: 20px !important;
  
      .offcanvas-title {
        font-size: 24px;
        font-weight: 600;
        color: $Text-Medium;
      }
  
      .edit-btn {
        font-size: 14px;
        font-weight: 600;
        padding: 12px;
        color: $Background-White;
        border-radius: 8px;
        background: $Primary-Gradient;
        width: 80px;
      }
  
      .close-btn {
        background: none;
        border: none;
        color: $Text-Light2;
      }
  
      .ri-close-line {
        font-size: 25px;
        color: $Text-Light2;
        padding: 0;
      }
    }
  
    .offcanvas-body {
      // overflow-y: hidden !important;
      @media (max-width: 719px) {
        .user-details-rs {
          flex: auto !important;
          padding: 0;
        }
        .user-details-section-col {
          padding: 0;
        }
      }
    }
  
    .user-details-section-col .user-details-col{
     @media (max-width:430px){
      margin-bottom: 16px;
     }
    }
  
    @media (max-width: 719px) {
      .details-section {
        // margin-top: 16px !important;
        height: auto;
      }
    }
  
    .details-section-second {
      border-radius: 16px;
      // height: 93.5%;
      background: var(--Background-BG, $Background-BG);
  
      // margin-left: 24px;
      // margin-bottom: 24px;
      .card-body {
        padding: 16px;
      }
  
      h5 {
        font-size: 18px;
        font-weight: 500;
        color: $Text-Medium;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
  
        .department-badge {
          border-radius: 8px !important;
          background: $States-Negative_light !important;
          color: $Primary-Medium !important;
          padding: 8px !important;
          font-size: 12px !important;
          font-weight: 500 !important;
        }
  
        .ri-contacts-book-2-line {
          font-size: 24px;
          color: $Primary-Medium;
        }
  
        .ri-map-pin-user-line {
          font-size: 24px;
          color: $Primary-Medium;
        }
  
        .ri-numbers-line {
          font-size: 24px;
          color: $Primary-Medium;
        }
  
        .ri-file-text-line {
          font-size: 24px;
          color: $Primary-Medium;
        }
  
        .ri-map-pin-line {
          font-size: 24px;
          color: $Primary-Medium;
        }
      }
  
  
      @media (max-width: 991px) {
        .emp-type {
          margin-top: 0px !important;
        }
      }
  
      .emp-type {
        margin-top: 24px;
  
        .detail-title {
          font-size: 14px;
          font-weight: 400;
          color: $Text-Medium3;
          margin-bottom: 8px;
        }
  
        .detail-value {
          font-size: 14px;
          font-weight: 600;
          color: $Text-Medium2;
          margin-bottom: 0;
          line-height: 15.9px;
        }
      }
  
      .detail-title {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 8px;
      }
  
      .detail-value {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 0;
        line-height: 15.9px;
      }
    }
  
    .details-section-third {
        border-radius: 16px;
        background: var(--Background-BG, $Background-BG);
        // height: calc(100vh - 37.3vh);
        // padding: 24px;
        // margin-left: 24px;
        // margin-bottom: 24px;
    
        h5 {
          font-size: 18px;
          font-weight: 500;
          color: $Text-Medium;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          gap: 8px;
    
          .department-badge {
            border-radius: 8px !important;
            background: $States-Negative_light !important;
            color: $Primary-Medium !important;
            padding: 8px !important;
            font-size: 12px !important;
            font-weight: 500 !important;
          }
    
          .ri-contacts-book-2-line {
            font-size: 24px;
            color: $Primary-Medium;
          }
    
          .ri-map-pin-user-line {
            font-size: 24px;
            color: $Primary-Medium;
          }
    
          .ri-numbers-line {
            font-size: 24px;
            color: $Primary-Medium;
          }
    
          .ri-file-text-line {
            font-size: 24px;
            color: $Primary-Medium;
          }
    
          .ri-map-pin-line {
            font-size: 24px;
            color: $Primary-Medium;
          }
        }
    
        .view-calender {
          text-align: end;
    
          p {
            font-size: 13px;
            font-weight: 600;
            color: $Primary-Medium;
          }
        }
    
        .detail-title {
          font-size: 14px;
          font-weight: 400;
          color: $Text-Medium3;
          margin-bottom: 4px;
        }
    
        .detail-value {
          font-size: 14px;
          font-weight: 600;
          color: $Text-Medium;
          margin-bottom: 0;
          line-height: 15.9px;
        }
    
        .table {
          .ri-eye-line {
            font-size: 18px;
            color: $Text-Medium2;
            margin-right: 8px;
          }
    
          .bx-edit {
            font-size: 18px;
            color: $Text-Medium2;
          }
        }
      }
      
    .details-section-four {
      border-radius: 16px;
      // height: 93.5%;
      background: var(--Background-BG, $Background-BG);
      // margin-left: 24px;
      // margin-bottom: 24px;
  
      .card-body {
        padding: 16px;
      }
  
      .rs-details {
        margin-top: 24px;
      }
      @media (max-width: 991px) {
        .rs-details {
          margin-top: 0px !important;
        }
      }
  
      h5 {
        font-size: 18px;
        font-weight: 500;
        color: $Text-Medium;
        margin-bottom: 24px;
      }
  
      .detail-title {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 8px;
      }
  
      .detail-value {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 0;
        line-height: 15.9px;
      }
    }
  
    .locationmap {
      border-radius: 12px;
  
      .gmaps {
        width: 100%;
        height: calc(100vh - 43.3vh);
      }
    }
  
    .details-section-third {
      border-radius: 16px;
      background: var(--Background-BG, $Background-BG);
  
      // margin-left: 24px;
      // margin-bottom: 24px;
  
      .card-body {
        padding: 16px;
      }
  
      h5 {
        font-size: 18px;
        font-weight: 500;
        color: $Text-Medium;
        margin-bottom: 12px;
      }
  
      .view-calender {
        text-align: end;
  
        p {
          font-size: 13px;
          font-weight: 600;
          color: $Primary-Medium;
        }
      }
  
      .detail-title {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 8px;
      }
  
      .detail-value {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 0;
        line-height: 15.9px;
      }
  
      .table {
        thead {
          tr {
            th {
              font-size: 12px;
              color: $Text-Medium2;
              font-weight: 600;
              padding: 16px 16px 16px 0px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 14px;
              color: $Text-Medium3;
              font-weight: 400;
              padding: 16px 16px 16px 0px;
            }
          }
        }
        .ri-eye-line {
          font-size: 18px;
          color: $Text-Medium2;
          margin-right: 8px;
          border-radius: 4px;
          background: $Text-Light3;
          padding: 4px;
        }
  
        .bx-edit {
          font-size: 18px;
          color: $Text-Medium2;
          border-radius: 4px;
          background: $Text-Light3;
          padding: 4px;
        }
      }
    }
  
    .details-section {
      border-radius: 16px;
      background: var(--Background-BG, $Background-BG);
      height: 100%;
  
      @media (max-width: 719px) {
        height: auto;
      }
  
      .card-body {
        padding: 24px;
      }
  
      .edit-main {
        text-align: center;
        margin-bottom: 32px;
      }
  
      .profile {
        max-width: 100%;
        margin-bottom: 16px;
      }
  
      h5 {
        font-size: 24px;
        font-weight: 600;
        color: $Text-Dark;
        margin-bottom: 8px;
      }
  
      .propemployee {
        font-size: 14px;
        font-weight: 500;
        color: $Text-Medium2;
        margin-bottom: 8px;
      }
  
      .proptype {
        font-size: 14px;
        font-weight: 500;
        color: $Text-Medium2;
        margin-bottom: 0;
      }
  
      .detail-people {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium2;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 12px;
  
        .icon-profile {
          font-size: 18px;
          color: $Primary-Medium;
        }
      }
    }
  
    .add-btn {
      color: $Primary-Medium;
      font-size: 14px;
      font-weight: 600;
      background: none;
      border: none;
    }
  
    .remove-btn {
      font-size: 32px;
      background: none;
      border: none;
      color: $States-Negative_Error;
      display: flex;
      align-items: center;
      margin-top: 15px;
    }
  }