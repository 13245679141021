//
// _dashboard.scss
//

.courses-calendar-dashboard {
  .date-calendar {
    .week {
      width: 35px;
      text-align: center;

      @media (max-width: 575.98px) {
        width: 28px;
      }

      .avatar-title {
        color: var(--#{$prefix}body-color);
        background-color: var(--#{$prefix}light);
        height: 35px;
      }

      &:hover {
        .avatar-title {
          color: var(--#{$prefix}primary);
        }
      }

      &:is(.active, .active:hover) {
        .avatar-title {
          height: 35px;
          color: $white;
          background-color: var(--#{$prefix}primary);
        }
      }
    }
  }
}

//arrow-none
.arrow-none {
  &::after {
    display: none;
  }
}

.sales-report-chart {
  @media (min-width: 1200px) and (max-width: 1650.98px) {
    margin-top: 0 !important;
  }
}

.owner-profile {
  .profile-card {
    border-radius: 1rem;
    height: 97%;
    .card-body {
      padding: 1.5rem;
    }
    .company-header {
      padding-bottom: 1rem;
      border-bottom: 1px solid $Text-Light3;
      margin-bottom: 1.5rem;
      .card-title {
        color: $Text-Medium;
        font-size: 1.125rem;
        font-weight: 400;
      }
      .app-search {
        height: auto;
        margin-right: 0.81rem;
        input {
          height: 2.75rem;
          color: $Text-Light1;
          font-size: 0.8125rem;
          font-weight: 400;
          border-radius: 0.5rem;
          border: 1px solid $Text-Light3;
        }
        span.search-widget-icon {
          top: 4px;
          color: $Text-Light2;
        }
      }
      .add-company {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    }
    .no-company {
      text-align: center;
      img {
        margin-top: 8rem;
      }
      h5 {
        margin-block: 4rem 4rem;
        color: $Text-Medium2;
        font-size: 1.125rem;
        font-weight: 400;
      }
    }
    .organization-card {
      .card {
        border-radius: 0.75rem;
        box-shadow: none;
        border: 1px solid $Text-Light3;
        margin-bottom: 1.5rem;
      }
      .card-body {
        padding: 0.75rem;
      }
      img {
        width: 100%;
        height: 6.25rem;
        width: 6.25rem;
      }
      .top-box {
        padding-bottom: 0.75rem;
        border-bottom: 1px solid $Text-Light3;
      }
      h4 {
        color: $Text-Medium;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.15rem;
        margin-bottom: 0.25rem;
      }
      h5 {
        color: $Text-Light1;
        font-size: 0.8125rem;
        font-weight: 400;
      }
      .staffData,
      .locationData {
        margin-bottom: 0;
      }
      .draft-btn {
        width: 100%;
        padding: 0.5rem 1rem;
      }
      .dropdown-toggle {
        color: $Text-Medium2;
      }
      .status-badge {
        font-weight: 400;
        border-radius: 1.5rem;
        padding: 0.25rem 0.5rem;
      }
    }
  }
  .profile-info-card {
    padding: 1.5rem;
    border-radius: 1rem;
    .bx-edit {
      font-size: 1.25rem;
      color: $Primary-Dark;
      text-align: end;
      margin-bottom: 1.5rem;
    }
    .user-img {
      width: 100%;
      max-width: 80%;
      display: flex;
      margin: auto;
    }
    h4 {
      color: $Text-Dark;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.8125rem;
      margin-block: 2.5rem 0.5rem;
    }
    .info {
      color: $Text-Medium2;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.1875rem;
    }
    .icon {
      font-size: 1.5rem;
      color: $Text-Light1;
      margin-bottom: 1rem;
      padding-right: 0.25rem;
    }
    .icon-text {
      color: $Text-Medium;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.1875rem;
      margin-bottom: 1rem;
      padding-left: 0.25rem;
      p {
        margin-bottom: 0;
      }
    }
    .progress-bar::before {
      display: none;
    }
    .custom-progress {
      height: 0.375rem;
      padding-block: 0;
    }
  }
}
.edit_profile {
  .modal-content {
    // min-height: 750px;
    padding: 1.5rem;
  }
  .modal-header h4 {
    color: $Text-Dark;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8125rem;
    margin: auto;
    padding-bottom: 1.5rem;
  }
  .custom-nav {
    padding: 1rem;
    border-top: 1px solid $Text-Light3;
    border-bottom: 1px solid $Text-Light3;
  }
  .form-label {
    color: $Text-Medium3;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.30625rem;
  }
  input,
  textarea,
  .form-select {
    color: $Text-Medium2;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
  }
  .bx-face,
  .ri-calendar-event-line,
  .ri-mail-line,
  .bx-phone-call,
  .bx-link-alt,
  .ri-map-pin-line {
    font-size: 1.1rem;
    color: $Text-Light2;
  }
  .prev-img {
    height: 6.25rem;
    width: 6.25rem;
    border: 1px dashed $Text-Medium3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancel-btn {
    color: $Primary-Dark;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.99375rem;
  }
  .save-next-btn {
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.99375rem;
    border-radius: 0.5rem;
    padding: 0.75rem;
    background: $Primary-Gradient;
  }
  .progress-nav .progress {
    top: 46%;
  }
  .progress-bar {
    margin-left: 120px;
    margin-right: 125px;
  }
  .progress-bar-tab {
    .nav-item {
      display: flex;
      align-items: baseline;
      // gap: 8px;

      .nav-name.active {
        font-weight: 600;
        text-transform: uppercase;
        color: $Primary-Medium;
      }
      .nav-name {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium3;
        text-transform: uppercase;
        background-color: $Background-White !important;
        padding-inline: 0.5rem;
      }
    }
  }
}

.organization-setting-page {
  .organization-setting-card {
    border-radius: 16px;
    .organization-setting-cardbody {
      padding: 0px 24px;
    }
  }
  .organization-company-navtab-view {
    border-right: 1px solid $Text-Light3;
    padding-right: 24px;
    padding-top: 24px;
    height: calc(100vh - 4vh);

    @media (max-width: 991px) {
      height: auto;
      border: none;
    }

    .company-overview-name {
      display: flex;
      align-items: center;
      // justify-content: center;
      gap: 16px;

      margin-bottom: 24px;

      h5 {
        font-size: 18px;
        font-weight: 600;
        color: $Text-Medium;
        margin-bottom: 0;
      }
    }
    .app-search {
      height: auto;
      width: 100%;
      margin-bottom: 24px;
      .serach-div {
        width: 100%;
      }
      input {
        border-radius: 8px;
        height: auto;
        width: 100%;
        background-color: $Background-BG;
      }
    }
    .setting-name-title {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 16px;
      color: #aaa;
    }
    .nav-link.active {
      border-radius: 0px 8px 8px 0px;
      border-left: 2px solid $Primary-Medium;
      background: $Primary-Light2;
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      font-weight: 500;
      color: $Primary-Medium;
      .ri-award-fill {
        font-size: 20px;
      }
      .ri-bar-chart-grouped-fill {
        font-size: 20px;
      }
    }
    .nav-link {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      font-weight: 500;
      border-left: 2px solid $Background-White;
      color: $Text-Dark;
      .ri-award-fill {
        font-size: 20px;
      }
      .ri-bar-chart-grouped-fill {
        font-size: 20px;
      }
    }
  }
  .role-list-page {
    padding: 24px 10px 24px 24px;

    .flatpickr-input {
      border: 1px solid #eaeaea;
    }

    .table {
      .ri-pencil-line {
        font-size: 18px;
        color: $Text-Medium2;
        margin-right: 8px;
        border-radius: 4px;
        background: $Text-Light3;
        padding: 4px;
      }

      .ri-delete-bin-line {
        font-size: 18px;
        color: $Text-Medium2;
        border-radius: 4px;
        background: $Text-Light3;
        padding: 4px;
      }
    }

    .company-role-box {
      border-radius: 8px;
      border: 1px solid $Text-Light3;
      background: $Background-White;
      padding: 16px;
      margin-bottom: 20px;

      .rolelist-btn {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1350px) {
          flex-direction: column;
          gap: 8px;
        }

        .view-work-force-btn {
          border-radius: 8px;
          background: $Background-BG;
          font-size: 13px;
          color: $Text-Medium;
          font-weight: 600;
          padding: 12px 16px;
          border: none;
        }
      }

      .section-logoname {
        display: flex;
        gap: 24px;
        margin-bottom: 24px;

        .role-logo {
          font-size: 18px;
          font-weight: 600;
          color: $Primary-Medium;
          background-color: $Primary-Light2;
          border-radius: 50%;
          width: 60px !important;
          height: 60px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h5 {
          font-size: 18px;
          font-weight: 500;
          color: $Text-Dark;
          margin-bottom: 8px;
        }

        .user-rolename-list {
          display: flex;
          gap: 9px;

          p {
            font-size: 13px;
            font-weight: 500;
            color: $Text-Medium;
            margin-bottom: 0;
          }
        }
      }
    }

    .role-navbar {
      border-bottom: 1px solid $Text-Light3;
      padding-bottom: 16px;
      margin-bottom: 24px;
    }

    .card-title {
      color: $Text-Medium;
      font-size: 18px;
      font-weight: 600;
    }
    .app-search {
      height: auto;
      margin-right: 0.81rem;
      input {
        height: 2.75rem;
        color: $Text-Light1;
        font-size: 0.8125rem;
        font-weight: 400;
        border-radius: 0.5rem;
        border: 1px solid $Text-Light3;
        background-color: $Background-BG;
      }
      span.search-widget-icon {
        top: 4px;
        color: $Text-Light2;
      }
    }
    .add-company {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      display: flex;
      gap: 8px;
      background: $Primary-Gradient;
    }
  }
}

.company-onboarding {
  .organization-details {
    .card {
      border-radius: 16px;
      // height: 860px;

      .next-page1 {
        // padding-top: 24px;
        // border-top: 1px solid var(--text-Light-3, $Text-Light3);
        // margin-top: 68px;
        text-align: end;

        .next-btn {
          padding: 0.75rem 1.5rem;
          color: $Background-White;
          font-size: 0.875rem;
          font-weight: 600;
          border-radius: 0.5rem;
          border: none;
          background: $Primary-Gradient;
        }
      }

      .card-footer {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .card-body {
        padding: 24px;
        padding-bottom: 0;
      }
    }

    h5 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
      color: $Text-Medium;
    }

    .previous-btn {
      color: $Text-Medium3;
      font-size: 11px;
      font-weight: 400;
      background: none;
      border: none;
    }

    .side-view-onboarding {
      .side-section {
        padding-top: 50px !important;
        padding: 25px;

        h4 {
          font-size: 24px;
          font-weight: 600;
          color: $Text-Medium;
          margin-bottom: 24px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: $Text-Light1;
          margin-bottom: 55px;
        }
      }

      .side-image {
        max-width: 100%;
        padding-bottom: 50px;
      }

      .div-side {
        @media (max-width: 991px) {
          text-align: center;
        }
      }
    }

    .onboarding-form {
      padding-left: 72px;
      padding-top: 50px;

      @media (max-width: 991px) {
        padding-left: 10px;
      }

      h5 {
        font-size: 16px;
        color: $Text-Medium;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .image-add {
        .form-label {
          font-size: 13px;
          font-weight: 400;
          color: $Text-Medium3;
        }

        p {
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 0;
          color: $Text-Light2;
          margin-top: 8px;
        }

        .image {
          width: 200px;
          height: 100px;
          margin-top: 8px;
        }
      }

      .next-page1 {
        padding-top: 24px;
        border-top: 1px solid var(--text-Light-3, $Text-Light3);
        margin-top: 68px;
        text-align: end;

        .next-btn {
          padding: 0.75rem 1.5rem;
          color: $Background-White;
          font-size: 0.875rem;
          font-weight: 600;
          border-radius: 0.5rem;
          border: none;
          background: $Primary-Gradient;
        }
      }
    }

    .previous-page {
      // margin-top: 40px;
      // padding-top: 24px;
      // border-top: 1px solid var(--text-Light-3, $Text-Light3);
      display: flex;
      justify-content: space-between;

      .previous-btn {
        padding: 0.75rem 1.5rem;
        color: #d40d12;
        font-size: 0.875rem;
        font-weight: 600;
        background: none;
        border: none;
      }

      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    }

    .add-btn {
      color: $Primary-Medium;
      font-size: 14px;
      font-weight: 600;
      background: none;
      border: none;
    }

    .progress-nav {
      .nav {
        justify-content: end;
        gap: 24px;
      }
    }

    .progress-bar-tab {
      .nav-item {
        display: flex;
        align-items: baseline;
        gap: 8px;

        .nav-name.active {
          font-weight: 600;
          text-transform: uppercase;
          color: $Text-Medium;
        }

        .nav-name {
          font-size: 14px;
          font-weight: 600;
          color: $Text-Light2;
          text-transform: uppercase;
        }
      }
    }

    .nav-progress {
      margin-bottom: 8px;
    }
  }
}

.add-role-canvas {
  border-radius: 24px 24px 0px 0px;

  .flatpickr-input {
    border: 1px solid #eaeaea;
  }

  .add-header {
    padding: 32px 24px 20px 24px;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Medium;
    }
    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
    .next-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
    }
  }
  .offcanvas-body {
    padding: 24px !important;

    h5 {
      font-size: 16px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 24px;
    }

    .input-title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
      color: $Text-Dark;
    }


    .btn-group {
        .btn {
          background: #eaeaea !important;
        }
    }


    .add-break-checkbox {
      // padding: 8px;
      // border-radius: 8px;
      // background: var(--Background-BG, $Background-BG);
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // width: 46px;
      // height: 46px;
      .form-check-input {
        margin: 0;
        width: 18px;
        height: 18px;
      }
    }
    .invalid-border {
      border-color: var(--tb-form-invalid-border-color) !important;
    }

    .btn-group {
      height: 45px !important;
    }

    .uploadsection {
      display: flex;
      padding: 4px 0px 4px 20px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border: 1px solid $Text-Light3;
      border-radius: 8px;

      p {
        margin-bottom: 0;
        padding: 5px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(71, 71, 71, 0.8);
      }
      .uploadlabel {
        width: 103px;
        height: 35px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $Background-White;
        border-radius: 4px;
        border: 1px solid $Primary-Medium;
        font-size: 11px;
        gap: 8px;
        margin-bottom: 0;
        background-color: $Primary-Medium;
        .bx-plus {
          font-size: initial;
        }
      }
    }
    .closeuser {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5.5px;
      border: none;
      padding-right: 20px !important;
    }

    // .form-check {
    //   .form-check-input {
    //     width: 18px;
    //     height: 18px;
    //   }
    //   .form-check-label {
    //     font-size: 14px;
    //     font-weight: 500;
    //     color: #474747;
    //     margin-left: 8px;
    //   }
    // }

    .pay-rate-details {
      border-radius: 8px;
      background: var(--Background-BG, $Background-BG);
      width: -webkit-fill-available;
      padding: 8px 12px;
      margin-bottom: 16px;

      h5 {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 0px;
      }

      .pay-rate-price {
        display: flex;
        align-items: center;
        gap: 8px;

        .ri-coins-line {
          font-size: 24px;
          color: $Text-Light2;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          color: $Text-Medium2;
          margin-bottom: 0;
        }
      }
    }
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
  }
}
