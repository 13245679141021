.location {
  .location-table-card {
    border-radius: 16px;

    .card-body {
      padding: 16px;
    }

    .leave-table {
      .table-responsive {
        padding-left: 16px;
        padding-right: 16px;
      }
      .table-centered {
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 600;
              color: $Text-Dark;
              padding: 16px;
            }
          }
        }

        tbody {
          tr {
            padding: 8px 0px;

            td {
              font-size: 14px;
              font-weight: 400;
              color: $Text-Medium2;
              padding: 16px;

              p {
                font-size: 12px;
                font-weight: 400;
                color: $Text-Light1;
              }

              h5 {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium;
              }

              .Approve {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Pending {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Reject {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .Inactive {
                font-size: 12px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Invited {
                font-size: 12px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Active {
                font-size: 12px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Inactive {
                font-size: 12px;
                font-weight: 600;
                color: $Text-Light1;
              }

              .active-status {
                font-size: 10px;
                font-weight: 400;
                color: $Text-Light1;
              }

              .Approved {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Partially {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Rejected {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
              .Delete {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
            }
          }
        }
      }
    }

  }
}

.clear-section {
  .clear-btn {
    padding: 0.75rem 1.5rem;
    color: $Primary-Medium;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
    background: none;
    @media (max-width: 991px) {
      margin-bottom: 8px;
    }
  }
}

.Apply-section {
  text-align: end;

  .next-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    background: $Primary-Gradient;
  }
}

.add-location {
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 425px) {
    padding: 0;
  }

  .location-create-tabs {
    @media (max-width: 991px) {
      width: 100%;
    }

    .location-navigation {
      @media (max-width: 425px) {
        width: 40% !important;
      }
    }

    .location-nav {
      @media (max-width: 425px) {
        justify-content: center !important;
      }
    }

    .back-bnt-btn {
      padding: 0.75rem 1.5rem;
      color: $Primary-Medium;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
      background: none;

      @media (max-width: 425px) {
        display: none;
      }
    }

    .sub-update-btn {
      padding: 0.75rem 1.5rem;
      color: #fff;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      display: flex;
      align-items: center;
      gap: 8px;
      background: linear-gradient(90deg, #d40d12 8.15%, #ff4f5b 82.74%);
    }
  }

  .location-navigation {
    @media (max-width: 425px) {
      width: 40% !important;
    }
  }

  .location-nav {
    @media (max-width: 425px) {
      justify-content: center !important;
    }
  }

  .back-bnt-btn {
    padding: 0.75rem 1.5rem;
    color: $Primary-Medium;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
    background: none;

    @media (max-width: 425px) {
      display: none;
    }
  }

  .sub-update-btn {
    padding: 0.75rem 1.5rem;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    background: linear-gradient(90deg, #d40d12 8.15%, #ff4f5b 82.74%);
  }

  .progress-bar {
    margin-left: 188px;
    margin-right: 160px;
    @media (max-width: 904px) {
      display: none;
    }
  }

  .progress-nav .nav {
    margin-inline: 0;
  }

  .progress-nav .progress {
    top: 44%;
  }

  .progress-bar-tab {
    .nav-item {
      display: flex;
      align-items: baseline;
      gap: 0;

      .nav-name.active {
        font-weight: 600;
        text-transform: uppercase;
        color: $Primary-Medium;
      }

      .nav-name {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium3;
        text-transform: uppercase;
        background-color: #f7f7f7 !important;
        padding-inline: 0.5rem;

        @media (max-width: 1350px) {
          display: none !important;
        }
      }
    }
  }
}

.add-location-form-card {
  border-radius: 16px;
  height: 100%;

  .card-header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .card-footer {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .card-body {
    padding: 24px;

    .add-doc {
      @media (max-width: 1024px) {
        flex-direction: column !important;
      }
    }

    .document-adjust-div {
      @media (max-width: 1090px) {
        justify-content: center;
        margin-bottom: 12px;
      }
      .remove-item-btn {
        @media (max-width: 1024px) {
          justify-content: center;
          margin-bottom: 12px;
        }
      }
    }

    .contact-row {
      @media (max-width: 1090px) {
        flex-direction: column;
      }
      .document-adjust-div {
        @media (max-width: 1090px) {
          justify-content: center;
          margin-bottom: 12px;
        }
        .remove-item-btn {
          @media (max-width: 1024px) {
            justify-content: center;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .patrol-table-div {
    .patrol-table {
      tbody {
        tr {
          padding: 8px 0px;
          td {
            // font-size: 14px;
            // font-weight: 400;
            // color: $Text-Medium2;
            // padding: 16px;
            div {
              background: khaki;
              border-radius: 8px;
              padding: 12px;
              margin-bottom: 12px;
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .dashboard-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    background: $Primary-Gradient;
  }

  .app-search {
    height: auto;
    input {
      border-radius: 8px;
      height: auto;
      width: 260px;
      background-color: $Background-BG;
    }
  }
  .btn-section {
    display: flex;
    justify-content: end;
    gap: 12px;

    @media (max-width: 408px) {
      gap: 4px !important;
    }

    .app-search {
      height: auto;
      input {
        border-radius: 8px;
        height: auto;
        width: 260px;
        background-color: $Background-BG;
      }
    }
  }

  .location-row {
    border-bottom: 1px solid $Text-Light3;
  }

  .location-title-details {
    margin-bottom: 24px;

    @media (max-width: 425px) {
      width: 60% !important;
    }

    h4 {
      margin-bottom: 0;
      color: $Text-Dark;
    }
  }

  .back-cancel-btn {
    padding-bottom: 8px;
    // margin-bottom: 24px;
    text-align: end;

    .btn {
      color: $Primary-Medium;
      font-size: 14px;
      font-weight: 600;
      border: none;
      background: none;
      padding-top: 0;
    }
  }

  .document-list {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding-bottom: 16px;
    align-items: center;
    border-bottom: 1px solid $Text-Light3;

    .document-name {
      display: flex;
      align-items: center;
      gap: 10px;

      .doc-check {
        width: 18px;
        height: 18px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium;
      }
    }

    .ri-edit-line {
      font-size: 24px;
      color: $Text-Medium2;
      margin-right: 16px;
    }

    .ri-delete-bin-line {
      color: $Primary-Medium;
      font-size: 24px;
    }
  }

  .choose-item {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 16px;

    .card-radio {
      .form-check-label {
        width: 140px;

        .icon {
          color: $Text-Medium;
        }

        h5 {
          font-size: 14px;
          font-weight: 400;
          color: $Text-Medium3;
        }
      }
    }
  }

  .previous-page {
    padding-top: 24px;
    border-top: 1px solid var(--text-Light-3, $Text-Light3);
    display: flex;
    justify-content: space-between;

    .previous-btn {
      padding: 0.75rem 1.5rem;
      color: #d40d12;
      font-size: 0.875rem;
      font-weight: 600;
      background: none;
      border: none;
    }

    .draft-btn {
      padding: 0.75rem 1.5rem;
      color: $Primary-Medium;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: 1px solid $Primary-Medium;
      background: none;
      margin-right: 16px;
    }

    .next-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
    }
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none !important;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
  }

  .next-page1 {
    padding-top: 24px;
    border-top: 1px solid var(--text-Light-3, $Text-Light3);
    text-align: end;

    .next-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
    }
  }
}

.external-side {
  padding: 59px;
  text-align: center;
  @include media-breakpoint-down(lg) {
    display: none;
  }

  .logo {
    margin-bottom: 16px;
    max-width: 100%;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #484848;
    margin-bottom: 100px;
  }

  .side-image {
    max-width: 100%;
  }
}

.add-location-client-offcanvas {
  border-radius: 24px 24px 0px 0px;

  .add-header-client {
    padding: 32px 24px 20px 24px;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Text-Light2;
    }

    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }

  .add-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    background: $Primary-Gradient;
    margin-top: 27px;
  }

  .image-add {
    .form-label {
      font-size: 13px;
      font-weight: 400;
      color: $Text-Medium3;
    }

    p {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0;
      color: $Text-Light2;
      margin-top: 8px;
    }

    .image {
      width: 250px;
      height: 125px;
      margin-top: 8px;
    }

    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      color: $Primary-Medium;
      background: none;
      border: none;
      padding: 0;
    }
  }

  .off-bottom {
    padding: 12px 24px 24px 24px;
    margin-top: 35px;
    border-top: 1px solid $Text-Light3;
    display: flex;
    justify-content: end;

    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
    }

    .off-confirm {
      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
        margin-left: 24px;
        width: 146px;
        margin-right: 16px;
      }

      .clear-btn {
        padding: 0.75rem 1.5rem;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        background: none;
        border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
      }
    }
  }

  .off-bottom1 {
    padding: 12px 24px 24px 24px;
    border-top: 1px solid $Text-Light3;
    display: flex;
    justify-content: end;

    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
    }

    .off-confirm {
      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
        margin-left: 24px;
        width: 146px;
        margin-right: 16px;
      }

      .clear-btn {
        padding: 0.75rem 1.5rem;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        background: none;
        border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
      }
    }
  }
}

.registered {
  text-align: center;

  img {
    width: 100%;
    max-width: 10%;
    margin-top: 150px;
  }

  .content {
    margin-block: 2rem;

    h3 {
      color: $Text-Dark;
      font-size: 30px;
      font-weight: 600;
    }

    p {
      color: $Text-Light1;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .dashboard-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    background: $Primary-Gradient;
  }
}

.location-details-offcanvas {
  border-radius: 24px 24px 0px 0px;
  min-height: 95vh !important;
  padding: 24px;

  .location-details-col {
    @media (max-width: 991px) {
      padding: 0;
    }
  }

  .location-view-offcanvas {
    padding: 0;
    padding-bottom: 20px !important;

    .avatar-sm {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $Text-Light3;
      border-radius: 4px;
      border: none;
      .ri-settings-2-line {
        font-size: 16px;
        color: $Text-Medium2;
      }
    }

    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Medium;
    }

    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      color: $Background-White;
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 80px;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Text-Light2;
    }

    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }

  .offcanvas-body {
    overflow-y: scroll;
  }

  .details-section-second {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    height: 100%;

    // margin-left: 24px;
    // margin-bottom: 24px;

    .card-body {
      padding: 24px;
    }

    .department {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      width: max-content;
      gap: 8px;
      border-radius: 8px;
      margin-bottom: 16px;
      border: 1px solid var(--Text-Light3, $Text-Light3);

      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
      }
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;

      .department-badge {
        border-radius: 8px !important;
        background: $States-Negative_light !important;
        color: $Primary-Medium !important;
        padding: 8px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
      }

      .ri-book-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-contacts-book-2-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-map-pin-line {
        font-size: 24px;
        color: $Primary-Medium;
      }
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 4px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 0;
      line-height: 15.9px;
    }
  }

  .locationmap {
    border-radius: 12px;

    .gmaps {
      width: 100%;
      height: 548px;
    }
  }

  .details-section-third {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    // height: calc(100vh - 37.3vh);
    // padding: 24px;
    // margin-left: 24px;
    // margin-bottom: 24px;

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;

      .department-badge {
        border-radius: 8px !important;
        background: $States-Negative_light !important;
        color: $Primary-Medium !important;
        padding: 8px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
      }

      .ri-contacts-book-2-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-map-pin-user-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-numbers-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-file-text-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-map-pin-line {
        font-size: 24px;
        color: $Primary-Medium;
      }
    }

    .view-calender {
      text-align: end;

      p {
        font-size: 13px;
        font-weight: 600;
        color: $Primary-Medium;
      }
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 4px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium;
      margin-bottom: 0;
      line-height: 15.9px;
    }

    .table {
      .ri-eye-line {
        font-size: 18px;
        color: $Text-Medium2;
        margin-right: 8px;
      }

      .bx-edit {
        font-size: 18px;
        color: $Text-Medium2;
      }
    }
  }

  .details-section {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    // height: calc(100vh - 44.3vh);

    // @media (max-width: 991px) {
    //   height: auto;
    // }

    .card-body {
      padding: 24px;
    }

    .edit-main {
      text-align: center;
      margin-bottom: 32px;
    }

    .profile {
      max-width: 100%;
      margin-bottom: 16px;
      margin-top: 20px;
    }

    h5 {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      margin-bottom: 8px;
    }

    .propemployee {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium2;
      margin-bottom: 0px;
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 0;
      line-height: 15.9px;
    }

    .proptype {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 0;
    }

    .detail-people {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium2;
      margin-bottom: 16px;

      .icon-profile {
        font-size: 18px;
        color: $Primary-Medium;
        margin-right: 12px;
      }
    }

    .detail-people1 {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium2;
      margin-bottom: 32px;

      .icon-profile {
        font-size: 18px;
        color: $Primary-Medium;
        margin-right: 12px;
      }
    }
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
  }

  .remove-btn {
    font-size: 32px;
    background: none;
    border: none;
    color: $States-Negative_Error;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}
