.client-module {
  .chart-badge {
    border-radius: 8px;
    background: $States-Success_Light !important;
    padding: 8px 12px;
    color: $States-Success_Positive;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    .ri-arrow-up-line {
      margin-right: 8px;
      vertical-align: middle;
      font-size: 18px;
    }
  }
  .chart-heading {
    color: $Text-Dark;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
  }
  .client-list-card {
    border-radius: 16px;
    .card-body {
      padding: 16px;
    }
    .app-search {
      height: auto;
      input {
        border-radius: 8px;
        height: auto;
        width: 260px;
        background-color: $Background-BG;
      }
    }
    .btn-section {
      display: flex;
      justify-content: end;
      gap: 16px;

      .next-btn {
        color: $Background-White;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 16px;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
        display: flex;
        align-items: center;
        gap: 8px;
        .bi-plus {
          font-size: 16px;
        }
        &:disabled {
          color: $Text-Dark;
          background: $Text-Light2;
          opacity: inherit;
        }
      }
    }
    .client-list-table {
      .table-responsive {
        padding-left: 16px;
        padding-right: 16px;
      }
      .table-centered {
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 600;
              color: $Text-Dark;
              padding: 16px;
            }
          }
        }
        tbody {
          tr {
            padding: 8px 0px;
            td {
              font-size: 14px;
              font-weight: 400;
              color: $Text-Medium2;
              padding: 16px;
              p {
                font-size: 12px;
                font-weight: 400;
                color: #999;
              }
              h5 {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium;
              }
            }
          }
        }
        .ri-eye-line {
          font-size: 16px;
          padding: 8px;
          color: $Text-Medium2;
        }
        .paid-status {
          color: $States-Success_Positive;
          font-size: 14px;
          font-weight: 600;
        }
        .pending-status {
          color: $States-Warning;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.client-profile-offcanvas {
  border-radius: 24px 24px 0px 0px;
  padding: 24px;
  .add-header {
    padding: 0;
    padding-bottom: 20px !important;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }
    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      color: $Background-White;
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 80px;
    }
    .close-btn {
      background: none;
      border: none;
    }
    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }
  .details-section-third {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    // height: calc(100vh - 37.3vh);
    // padding: 24px;
    // margin-left: 24px;
    // margin-bottom: 24px;

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;

      .department-badge {
        border-radius: 8px !important;
        background: $States-Negative_light !important;
        color: $Primary-Medium !important;
        padding: 8px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
      }

      .ri-contacts-book-2-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-map-pin-user-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-numbers-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-file-text-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-map-pin-line {
        font-size: 24px;
        color: $Primary-Medium;
      }
    }

    .view-calender {
      text-align: end;

      p {
        font-size: 13px;
        font-weight: 600;
        color: $Primary-Medium;
      }
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 4px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium;
      margin-bottom: 0;
      line-height: 15.9px;
    }

    .invalid-border{
      border-color: var(--tb-form-invalid-border-color) !important;
    }

    .next-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
    }

    .table {
      .ri-eye-line {
        font-size: 18px;
        color: $Text-Medium2;
        margin-right: 8px;
      }

      .bx-edit {
        font-size: 18px;
        color: $Text-Medium2;
      }
      .ri-pencil-line{
        font-size: 18px;
        color: $Text-Medium2;
        margin-right: 8px;
      }
      .ri-delete-bin-line{
        font-size: 18px;
        color: $Text-Medium2;
      }
    }
  }
  .details-section-second {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    height: 100%;

    // margin-left: 24px;
    // margin-bottom: 24px;

    .card-body {
      padding: 24px;
    }

    .department {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      width: max-content;
      gap: 8px;
      border-radius: 8px;
      margin-bottom: 16px;
      border: 1px solid var(--Text-Light3, $Text-Light3);

      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
      }
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;

      .department-badge {
        border-radius: 8px !important;
        background: $States-Negative_light !important;
        color: $Primary-Medium !important;
        padding: 8px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
      }

      .ri-book-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-contacts-book-2-line {
        font-size: 24px;
        color: $Primary-Medium;
      }

      .ri-map-pin-line {
        font-size: 24px;
        color: $Primary-Medium;
      }
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 4px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 0;
      line-height: 15.9px;
    }
  }
  .ClientListMap {
    border-radius: 12px;
    .gmaps {
      height: 300px;
    }
  }
  .details-section {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    padding: 24px;

    .edit-main {
      text-align: center;
      margin-bottom: 32px;
    }

    .profile {
      max-width: 100%;
      margin-bottom: 16px;
      margin-top: 20px;
    }

    h5 {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      margin-bottom: 8px;
    }

    .propemployee {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium2;
      margin-bottom: 0px;
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium;
      margin-bottom: 0;
      line-height: 15.9px;
    }
  }
}
.invoice-canvas {
  .invoice-block {
    .invoice-img {
      margin-top: 100px;
      width: 100%;
    }
    h4 {
      margin-block: 100px 12px;
      text-align: center;
      color: $Text-Dark;
      font-size: 24px;
      font-weight: 600;
    }
    h5 {
      text-align: center;
      color: $Text-Medium2;
      font-size: 18px;
      line-height: normal;
      font-weight: 500;
    }
  }
}
.add-invoice {
  border-radius: 24px 24px 0px 0px;
  padding: 24px;
  .add-header {
    padding: 0;
    padding-bottom: 20px !important;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }
    .close-btn {
      background: none;
      border: none;
    }
    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }
  .ri-file-text-line,
  .bx-face,
  .ri-calendar-2-line {
    color: $Text-Light2;
  }
  .footer-add-button {
    border-top: 1px solid $Text-Light3;
    padding-top: 12px;
    display: flex;
    justify-content: end;
    .add-btn {
      color: $Background-White;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      border-radius: 8px;
      width: 150px;
      padding: 12px 16px;
      background: $Primary-Gradient;
    }
  }

  .add-timesheet-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none !important;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
  }


  .table-1 {
    .table-header {
      th {
        background-color: $Background-BG;
        color: $Text-Dark;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        padding: 16px;
      }
    }
    tbody {
      td {
        color: $Text-Medium2;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        padding: 16px;
      }
    }
  }
  .table-2 {
    .table-header {
      background-color: $Background-BG;
      color: $Text-Medium;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      padding: 16px;
    }
    th {
      color: $Text-Medium;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      padding: 16px;
    }
    tbody {
      td {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        padding: 16px;
      }
    }
  }
}
.view-invoice {
  border-radius: 24px 24px 0px 0px;
  min-height: 90vh !important;
  padding: 24px;
  .add-header {
    padding: 0;
    padding-bottom: 20px !important;

    .export-btn {
      color: $Primary-Dark;
      font-size: 14px;
      font-weight: 600;
      border-radius: 0.5rem;
      border-color: $Primary-Dark;
      background: none;
      display: flex;
      align-items: center;
      gap: 8px;
  
      .bx-export {
        font-size: 16px;
      }
    }
    
    .archive-btn {
      padding: 4px 10px;
      margin-right: 16px;
      border-radius: 4px;
      background: $States-Negative_light;
      color: $Primary-Medium;
      border: none;
      .ri-archive-line {
        font-size: 16px;
      }
    }
    .file-btn {
      padding: 4px 10px;
      margin-right: 16px;
      border-radius: 4px;
      background: $States-Success_Light;
      color: $Background-Success;
      border: none;
      .ri-file-text-line {
        font-size: 16px;
      }
    }
    .download-btn {
      padding: 4px 10px;
      border-radius: 4px;
      background: $Text-Light3;
      color: $Text-Medium2;
      border: none;
      .ri-download-line {
        font-size: 16px;
      }
    }
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      display: flex;
      align-items: center;
      .success-badge {
        border-radius: 24px;
        margin-left: 8px;
        padding: 6px 16px;
        color: $Background-Success;
        background-color: $States-Success_Light !important;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
      }
      .danger-badge {
        border-radius: 24px;
        margin-left: 8px;
        padding: 6px 16px;
        color: $Primary-Dark2;
        background-color:$States-Negative_light !important;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
      }
    }
    .edit-btn {
      padding: 12px 16px;
      width: 80px;
      border-radius: 8px;
      background: $Primary-Gradient;
    }
    .close-btn {
      background: none;
      border: none;
    }
    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }
  .form-heading {
    color: $Text-Medium;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  .line {
    margin-bottom: 16px;
    border: 1px solid $Background-BG;
  }
  .detail-box {
    border-radius: 16px;
    padding: 16px;
    background-color: $Background-BG-light-3;
    box-shadow: none;
    margin-bottom: 1rem;
    h5 {
      color: $Text-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
    }
    .title {
      margin-top: 24px;
      color: $Text-Medium3;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 8px;
    }
    .data {
      color: $Text-Medium2;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  .table-1 {
    .table-header {
      th {
        background-color: $Background-BG;
        color: $Text-Dark;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        padding: 16px;
      }
    }
    tbody {
      td {
        color: $Text-Medium2;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        padding: 16px;
      }
    }
  }
  .table-2 {
    .table-header {
      background-color: $Background-BG;
      color: $Text-Medium;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      padding: 16px;
    }
    th {
      color: $Text-Medium;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      padding: 16px;
    }
    tbody {
      td {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        padding: 16px;
      }
    }
  }
  .add-more-btn {
    padding: 12px 16px;
    // width: 80px;
    border-radius: 8px;
    margin-left: 15px;
    background: $Primary-Gradient;
  }
  .ri-file-text-line,
  .ri-calendar-event-line,
  .bx-face,
  .ri-building-line,
  .ri-mail-line,
  .ri-map-pin-2-line {
    font-size: 16px;
    color: $Text-Light2;
  }
}
.invoice-document {
  border-radius: 8px 8px 0px 0px;

  

  .modal-content {
    border: none;
  }
  .invoice-file {
    .card-title {
      // border-radius: 8px 8px 0px 0px;
      padding: 16px 24px;
      background: $Primary-Gradient;
      .head-logo {
        width: 100%;
        max-width: 10%;
      }
      h2 {
        color: $Background-White;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
      }
    }
    .card-body {
      padding: 24px;
      .line {
        margin-block: 24px;
        border: 1px solid $Text-Light3;
      }
      .success-badge {
        border-radius: 24px;
        padding: 2px 12px;
        color: $Background-Success;
        background-color: $States-Success_Light !important;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
      }
      .title {
        color: $Text-Medium3;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
      }
      .data {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
      }
      .title2 {
        color: $Text-Medium3;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
      }
      .data2 {
        color: $Text-Medium3;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
      .data3 {
        color: $Text-Medium;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
      .table-1 {
        .table-header {
          th {
            background-color: $Background-BG;
            color: $Text-Dark;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            padding: 16px;
          }
        }
        tbody {
          td {
            color: $Text-Medium2;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            padding: 16px;
          }
        }
      }
      .table-2 {
        .table-header {
          background-color: $Background-BG;
          color: $Text-Medium;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          padding: 16px;
        }
        th {
          color: $Text-Medium;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          padding: 16px;
        }
        tbody {
          td {
            color: $Text-Medium;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            padding: 16px;
          }
        }
      }
    }
    .card-footer {
      height: 20px;
      padding: 16px 24px;
      border-radius: 0px 0px 8px 8px;
      background: $Primary-Gradient;
    }
  }
  .modal-footer {
    padding: 16px 24px;
    text-align: end;
    .send-btn {
      color: $Primary-Dark;
      background: $Background-White;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      padding: 12px 16px;
      width: 120px;
    }
    .download-btn {
      color: $Background-White;
      background: $Primary-Gradient;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      padding: 12px 16px;
      width: 140px;
      margin: 0 0 0 12px;
    }
    .ri-send-plane-fill,
    .ri-download-line {
      margin-right: 8px;
      font-size: 16px;
      vertical-align: middle;
    }
  }
 
}
.add-client-offcanvas {
  border-radius: 24px 24px 0px 0px;
  padding: 24px;
  .add-header {
    padding: 0;
    padding-bottom: 20px !important;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      display: flex;
      align-items: center;
    }
    .close-btn {
      background: none;
      border: none;
    }
    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }
  .image {
    width: 200px;
    height: 100px;
    margin-top: 8px;
  }
  .add-btn {
    color: $Background-White;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    border-radius: 8px;
    width: 150px;
    padding: 12px 16px;
    background: $Primary-Gradient;
  }

  .invalid-border{
    border-color: var(--tb-form-invalid-border-color) !important;
  }

  .next-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    background: $Primary-Gradient;
  }


  .footer-add-button {
    border-top: 1px solid $Text-Light3;
    padding-top: 12px;
    display: flex;
    justify-content: end;
    .add-btn {
      color: $Background-White;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      border-radius: 8px;
      width: 150px;
      padding: 12px 16px;
      background: $Primary-Gradient;
    }
  }
  .edit-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    padding: 0;
  }
  .billing-title {
    color: $Text-Medium2;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }
  .ri-ancient-pavilion-line,
  .ri-coins-line,
  .bx-face,
  .ri-building-line,
  .ri-mail-line,
  .ri-map-pin-2-line {
    font-size: 16px;
    color: $Text-Light2;
  }
}
