.filter-canvas {
  .filter-canvas {
    border-bottom: 1px solid $Text-Light3;

    .offcanvas-title {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
    }
  }
  .offcanvas-body {
    padding: 16px;
  } 

  .filter-1 {
    // border-bottom: 1px solid $Text-Light3;

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 16px;
    }

    p {
      text-align: end;
      font-size: 12px;
      font-weight: 600;
      color: $Primary-Medium;
      margin-bottom: 16px;
    }

    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
    }
  }

  .filter-2 {
    padding-top: 2rem;
    // border-bottom: 1px solid $Text-Light3;

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 16px;
    }

    p {
      text-align: end;
      font-size: 12px;
      font-weight: 600;
      color: $Primary-Medium;
      margin-bottom: 16px;
    }

    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
    }
  }
}

.app-search {
  height: auto;
  input {
    border-radius: 8px;
    height: auto;
    width: 260px;
    background-color: $Background-BG;
  }
}
.btn-section {
  display: flex;
  justify-content: end;
  gap: 12px;

  @media (max-width: 430px) { 
    gap: 4px !important;
    justify-content: space-evenly;
  }

  .app-search {
    height: auto;

    @media (max-width:'1212px'){
      display: none !important;
    }

    input {
      border-radius: 8px;
      height: auto;
      width: 260px;
      background-color: $Background-BG;
    }
  }

  .next-btn {
    padding: 12px;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    background: $Primary-Gradient;
    .bi-plus {
      font-size: 16px;
    }
    .ri-add-fill {
      font-size: 16px;
    }
    &:disabled {
      color: $Text-Dark;
      background: $Text-Light2;
      opacity: inherit;
    }
    @media (max-width: 430px) { 
      gap: 6px;
      font-size: 12px;
    }
  }

  .filter-btn {
    color: $Text-Dark;
    font-size: 13px;
    font-weight: 400;
    border: none;
    padding: 8px 16px;
    background: none !important;
    display: flex;
    align-items: center;
    gap: 4px;

    @media (max-width: 430px) { 
      padding: 8px 12px;
    }

    .ri-list-settings-line {
      // color: $Text-Medium2;
      font-size: 20px;
    }
  }

  .approve-btn {
    color: $Text-Medium;
    font-size: 14px;
    font-weight: 600;
    border-color: $Text-Light2;
    border-radius: 8px;
    background: $Text-Light2;
    display: flex;
    width: 120px;
    height: 48px;
    padding: 12px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .ri-arrow-down-s-line {
      font-size: 16px;
    }
  }

  .unapprove-btn {
    color: $Background-White;
    font-size: 14px;
    font-weight: 600;
    border-color: none;
    border-radius: 8px;
    background: $Primary-Gradient;
    display: flex;
    width: 120px;
    height: 48px;
    padding: 12px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .ri-arrow-down-s-line {
      font-size: 16px;
    }
  }
  .export-btn {
    color: $Primary-Dark;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0.5rem;
    border-color: $Primary-Dark;
    background: none;
    display: flex;
    align-items: center;
    gap: 8px;

    .bx-export {
      font-size: 16px;
    }
  }
  .unexport-btn {
    color: $Text-Light1;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0.5rem;
    border-color: $Text-Light1;
    background: none;
    display: flex;
    align-items: center;
    gap: 8px;

    .bx-export {
      font-size: 16px;
    }
  }
}
.input-group {
  .btn {
    // display: flex;
    // align-items: center;
    // gap: 8px;
  }
}

// .form-icon .form-control-icon {
//   border-radius: 8px;
//   border: 1px solid $Text-Light3;
//   // background: $Background-White;
// }

// .form-select-lg {
//   color: $Text-Light2;
// }


.form-icon i {
  color: $Text-Light2;
  height: 45px;
}
.form-select-icon i {
  font-size: 16px !important;
  margin-top: 1.7rem !important;
}
// .invalid-feedback{
//   position: absolute;
//   top: 50px;
// }

// .input-group {
//   border-radius: 8px !important;
//   // border: 1px solid $Text-Light3 !important;
//   background: $Background-White !important;
// }


