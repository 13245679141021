// 
// _fullcalendar.scss
// 


.fc td,
.fc th, .fc .fc-scrollgrid{
    border: $table-border-width solid $table-border-color !important;
}

.fc {
    .fc-toolbar {
        h2 {
            font-size: var(--#{$prefix}font-lg);
            line-height: 30px;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {

            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            >*>* {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }
    }

    .fc-col-header-cell {
        background-color: var(--#{$prefix}light);
    }

    .fc-col-header-cell-cushion {
        display: block !important;
        padding: 8px 4px !important;
    }

    .fc-daygrid-day-number {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--#{$prefix}font-xs);
        font-weight: $font-weight-medium;
        margin: 2px;
    }

    .fc-daygrid-day {
        &.fc-day-today {
            background-color: rgba(var(--#{$prefix}secondary-rgb), 0.1);
    
            .fc-daygrid-day-number {
                background-color: var(--#{$prefix}primary);
                color: $white;
            }
        }
    }

    .fc-daygrid-day {
        &.fc-day-today {
            background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
        }
    }
    
    .fc-timegrid-col {
        &.fc-day-today {
            background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
        }
    }

    .fc-list-event:hover td {
        background: transparent;
    }

    .fc-list-event-title {
        a {
            color: $white !important;
        }
    }

    .fc-col-header,
    .fc-daygrid-body,
    .fc-scrollgrid-sync-table {
        width: 100% !important;
    }

    .fc-scrollgrid-section>*{
        border-left: $table-border-width solid $table-border-color;
        border-top: $table-border-width solid $table-border-color;
    }

    .fc-scrollgrid-section-liquid>td{
        border-top: 0;
    }
}

.fc-theme-bootstrap a:not([href]) {
    color: var(--#{$prefix}body-color);
}

.fc-event {
    background-color: transparent;
    // color: $white;
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: var(--#{$prefix}secondary-bg);
    border-color: $border-color;
    color: $gray-700;
    text-transform: capitalize !important;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: var(--#{$prefix}primary);
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px !important;
    border: none !important;
    cursor: move !important;
    font-size: var(--#{$prefix}font-base);
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}


.fc-event-dot {
    background-color: var(--#{$prefix}primary);
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background-color: var(--#{$prefix}primary);
    background-color: transparent !important;
}

// .fc-daygrid-dot-event {
//     color: $white !important;
// }

.fc-event-title,
.fc-sticky {
    font-weight: $font-weight-semibold !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fc-daygrid-event-dot {
    border-color: $white !important;
    display: none;
}

.fc-event-time {
    display: none;
}

.fc-event .fc-content {
    color: $white;
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 0px;
        margin: 0;
        // margin: 6px 0;
    }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content {
        color: var(--#{$prefix}light);
    }
}

.fc-prev-button,
.fc-next-button {
    position: relative;
    // padding: 6px 8px!important;
    padding: 1px 2px!important;
}

.fc-next-button {
    .fa-chevron-right{
        font-size: 1.5em;
        vertical-align: middle;
        &::before {
            content: "\e901";
            font-family: fcicons;
        }
    }
}

.fc-prev-button {
    .fa-chevron-left {
        font-size: 1.5em;
        vertical-align: middle;
        &::before {
            content: "\e900";
            font-family: fcicons;
        }
    }
}

.fc-toolbar-chunk {
    .btn-group {
        .btn {
            color: var(--#{$prefix}secondary);
            background-color: rgba(var(--#{$prefix}secondary-rgb), 0.05);
            border-color: rgba(var(--#{$prefix}secondary-rgb), 0.05);;
            box-shadow: none;

            &:hover,
            &.active {
                color: $white;
                background-color: var(--#{$prefix}secondary);
                border-color: var(--#{$prefix}secondary);
            }
        }
    }

    .fc-today-button {
        background-color: var(--#{$prefix}secondary) !important;
        border-color: var(--#{$prefix}secondary) !important;
    }
}

.fc {
    .fc-button-primary:not(:disabled).fc-button-active, 
    .fc-button-primary:not(:disabled):active{
        background-color: var(--#{$prefix}secondary) !important;
        border-color: var(--#{$prefix}secondary) !important;
        color: $white !important;
    }
}

.fc-toolbar {
    @media (max-width: 575.98px) {
        flex-direction: column;
        gap: 16px;
    }
}

#upcoming-event-list {
    .card {
        &:last-child {
            margin-bottom: 6px !important;
        }
    }
}

#event-modal {
    .event-details {
        display: none;
    }

    .view-event {
        .event-form {
            display: none;
        }

        #event-category-tag {
            display: none;
        }

        .event-details {
            display: block;
        }
    }
}

.fc-daygrid-event-harness {
    .fc-daygrid-event {
        backdrop-filter: blur(4px);
    }
}

.fc-timegrid-event-harness {
    .fc-timegrid-event {
        backdrop-filter: blur(4px);
    }
}


.fc-timegrid-slots {
    table {
        tr {
            border-color: var(--#{$prefix}border-color) !important;
        }
    }
}

.fc-list-table {
    border-color: var(--#{$prefix}border-color);
}

@each $color,
$value in $theme-colors {
    .fc-daygrid-event-harness {
        .fc-daygrid-event {
            &.bg-#{$color}-subtle {

                .fc-event-title,
                .fc-event-main {
                    color: $value !important;
                }
            }
        }
    }

    .fc-timegrid-event-harness {
        .fc-timegrid-event {
            &.bg-#{$color}-subtle {
                .fc-event-title {
                    color: $value !important;
                }
            }
        }
    }

    .fc-list-table {
        .fc-list-event {
            &.bg-#{$color}-subtle {
                color: $value !important;

                .fc-list-event-title {
                    >a {
                        color: $value !important;
                    }
                }

                .fc-list-event-dot {
                    border-color: $value;
                }
            }
        }
    }
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle{
    .fc-event-main, .fc-event-title {
        color:  var(--#{$prefix}dark) !important;
    }
}

.fc-direction-ltr{
    direction: ltr;
    .fc-toolbar>*>:not(:first-child){
        margin-left: 0.75em;
    }
}

.calendar-event-card{
    @media (min-width: 1200px) {
        width: 450px;
        max-width: 100%;
    }
    @media (min-width: 1600px) {
        margin-top: calc(#{$grid-gutter-width} * -1);
        margin-right: calc(#{$grid-gutter-width} * -1);
    }
}

.fc{
    .fc-toolbar{
        &.fc-header-toolbar{
            padding: 0 0 8px 0;
            border-bottom: 1px dashed var(--#{$prefix}border-color);
            margin-bottom: 8px !important;
        }

    }

    .fc-dayGridMonth-view{
        padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
    }

    a:not([href]){
        color: var(--#{$prefix}body-color);
    }
}
[data-theme="modern"],[data-theme="minimal"]{
    .calendar-event-card{
        @media (min-width: 1600px) {
            margin-right: -16px;
        }
    }
}

.fc-theme-standard .fc-list {
    border-color: var(--#{$prefix}border-color);
}

.fc .fc-list-sticky .fc-list-day>* {
    background: var(--#{$prefix}secondary-bg);
}